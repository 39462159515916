import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { from, map, Observable } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { Masks } from 'src/app/shared/constants/masks';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { CampaignComboboxResponseDTO } from 'src/app/shared/domains/campaign';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { ClientsWithGroup } from 'src/app/shared/domains/post';
import { User } from 'src/app/shared/domains/user';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { GamificationService } from 'src/app/shared/services/gamification-config.service';
import { NoticeService } from 'src/app/shared/services/notice.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { AlertUserTypesWithLabels } from 'src/app/shared/types/alert-user.type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

@Component({
  selector: 'app-register-notice',
  templateUrl: './register-notice.component.html',
  styleUrls: ['./register-notice.component.scss'],
})
export class RegisterNoticeComponent implements OnInit, AfterViewInit {
  @Input()
  visible: boolean = false;

  @Input()
  noticeId: number | null = null;

  @Input()
  registerMode: 'create' | 'view' | 'edit' = 'create';

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  get isDisabled() {
    return this.registerMode === 'view' || (!this.canCreate && !this.canEdit);
  }

  clientUserId?: number;
  image!: File | undefined;
  clients: ClientDTO[] = [];
  userTypes = AlertUserTypesWithLabels;
  mask = Masks;

  selectedClientsControl = new FormControl([], Validators.required);

  form: FormGroup = this.formBuilder.group({
    id: [''],
    url: [''],
    name: ['', Validators.required],
    description: [''],
    imgUri: [''],
    recurrence: ['', Validators.required],
    alertType: [[], Validators.required],
    startTimestamp: ['', Validators.required],
    endTimestamp: ['', Validators.required],
    campaignId: [null],
    campaignName: [null],
  });

  gamificationFields: KeyValue<string, string>[] = [
    {
      key: 'GamificationRead',
      value: 'Visualizar',
    },
  ] as KeyValue<string, string>[];

  startDateFormInvalid = false;
  endDateFormInvalid = false;

  selectedImage: any = null;
  isDisabledGrid = false;
  user: User = new User();
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  characterCountDescription: number = 0;
  characterCountName: number = 0;
  isClientView = false;
  isEditionMode: boolean = false;
  stepperOrientation!: Observable<StepperOrientation>;

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  @ViewChild('stepper')
  stepper?: MatStepper;

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private noticeService: NoticeService,
    private toastComponent: ToastComponent,
    private cdr: ChangeDetectorRef,
    private dataImportService: DataImportService,
    private userClientService: UserClientService,
    private permissionsService: NgxPermissionsService,
    public globalFunctions: GlobalFunctions,
    public gamificationService: GamificationService,
    breakpointObserver: BreakpointObserver,
    private rolesService: NgxRolesService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.gamificationService.registerGamificationFields(
      this.gamificationFields,
      this.form
    );

    this.form.get('campaignId')?.valueChanges.subscribe((value) => {
      if (value) {
        this.gamificationFields.forEach((field) => {
          this.form
            .get(this.gamificationService.getAllowFieldName(field.key))
            ?.disable({ emitEvent: false });
          this.form
            .get(this.gamificationService.getAllowFieldName(field.key))
            ?.setValue(false, { emitEvent: false });
          this.form
            .get(this.gamificationService.getValueFieldName(field.key))
            ?.disable({ emitEvent: false });
          this.form
            .get(this.gamificationService.getValueFieldName(field.key))
            ?.setValue(null, { emitEvent: false });
        });
        this.form.get('isGeral')?.setValue(false);
        this.form.get('isGeral')?.disable();
        return;
      }
      this.gamificationFields.forEach((field) => {
        this.form
          .get(this.gamificationService.getValueFieldName(field.key))
          ?.setValue(null, { emitEvent: false });
        this.form
          .get(this.gamificationService.getAllowFieldName(field.key))
          ?.setValue(true, { emitEvent: false });
        this.form
          .get(this.gamificationService.getAllowFieldName(field.key))
          ?.enable({ emitEvent: false });
      });
      this.form.get('isGeral')?.setValue(true);
      this.form.get('isGeral')?.enable();
    });
  }

  ngAfterViewInit(): void {
    this.stepper!._getIndicatorType = () => 'number';
  }

  async getPermissions() {
    this.canCreate = await this.permissionsService.hasPermission(
      this.isClientView
        ? ProfilePermissions.CREATE_CLIENT_NOTICES
        : ProfilePermissions.CREATE_NOTICES
    );
    this.canEdit = await this.permissionsService.hasPermission(
      this.isClientView
        ? ProfilePermissions.UPDATE_CLIENT_NOTICES
        : ProfilePermissions.UPDATE_NOTICES
    );
    this.canDelete = await this.permissionsService.hasPermission(
      this.isClientView
        ? ProfilePermissions.DELETE_CLIENT_NOTICES
        : ProfilePermissions.DELETE_NOTICES
    );
  }

  async ngOnInit() {
    from(this.rolesService.hasOnlyRoles('ADMIN')).subscribe((isAdmin) => {
      this.isClientView = !isAdmin;
      if (!this.isClientView) {
        this.selectedClientsControl.removeValidators(Validators.required);
        this.selectedClientsControl.updateValueAndValidity();
      }
      this.initOptions();
      if (this.isClientView) {
        this.setFormClientNotice();
      }
    });
  }

  async initOptions() {
    await this.getPermissions();
    if (this.noticeId) await this.getEditData(this.noticeId);
    if (this.registerMode === 'view') {
      this.form.disable({ emitEvent: false });
    }
  }

  setFormClientNotice() {
    this.form.get('alertType')?.disable();
    this.form.get('alertType')?.setValue(['APP']);
    this.cdr.detectChanges();
  }

  handleChangeStartDate() {
    this.startDateFormInvalid = false;
  }

  async getClientData() {
    const result =
      await this.userClientService.getResumedUserClientByUserIdAndClientId(
        this.user?.id ?? 0
      );
    this.clientUserId = result.id;
    this.setFormClientNotice();
  }

  handleChangeEndDate() {
    this.endDateFormInvalid = false;
  }

  updateCharacterCountName(value: string): void {
    this.characterCountName = value.length;
  }

  updateCharacterCountDescription(value: string): void {
    this.characterCountDescription = value.length;
  }

  onImgAdded(event: any): void {
    const file = event.target.files[0];
    if (file) this.handleFile(file);
  }

  handleDisabledGrid() {
    const alertType = this.form.get('alertType')?.value;

    this.isDisabledGrid =
      Array.isArray(alertType) &&
      alertType.some((type) => ['INTERNAL_USER', 'PCV'].includes(type));
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file: File): void {
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 15 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo PNG ou JPG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido!',
        'Por favor, selecione um arquivo com tamanho máximo de 15MB.'
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      const img = new Image();
      img.src = target.result as string;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const isCorrectSize = width === 1080 && height === 1350;

        if (!isCorrectSize) {
          this.toastComponent.showWarningCustomMessage(
            'Proporção Inválida!',
            'A imagem deve ter uma proporção de 4:5 (1080 x 1350 pixels).'
          );
          return;
        }

        this.removeImg(file);
        this.image = file;
        this.selectedImage = target.result as string;
      };

      img.onerror = (error) => {
        console.error(
          'Erro ao ler o arquivo. Não foi possível validar as dimensões da imagem:',
          error
        );
        this.toastComponent.showWarningCustomMessage(
          'Erro ao ler o arquivo!',
          'Por favor, tente novamente.'
        );
      };
    };

    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };

    reader.readAsDataURL(file);
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  getFileName() {
    return `IMG_${this.form.get('name')?.value}`
      .replace(' ', '_')
      .toLocaleUpperCase();
  }

  removeImg(fileInput: any) {
    if (this.form.get('id')?.value && this.form.get('imgUri')?.value) {
      this.form.get('hasBeenImageDeleted')?.setValue(true);
    }

    this.selectedImage = null;
    this.image = undefined;
    fileInput.value = '';
  }

  async getImage(imgUrl?: string) {
    this.form.get('imgUri')?.setValue(imgUrl);

    if (imgUrl) {
      const blob = await this.dataImportService.getImage(imgUrl);
      const file = new File([blob], 'img.' + this.getImageExtension(blob), {
        type: blob?.type,
      });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
      this.image = file;
    }
  }

  getImageExtension(blob: Blob): string {
    const matches = blob?.type?.match(/[^/]*$/);
    if (matches && matches?.length > 0) {
      return matches[0];
    }
    return '';
  }

  async getEditData(id: number) {
    this.isEditionMode = true;

    const noticeData = this.isClientView
      ? await this.noticeService.getClientNotice(id)
      : await this.noticeService.getNotice(id);

    this.clientUserId = noticeData.clientId;

    const types = this.isClientView
      ? [noticeData.type]
      : noticeData.targetAudience?.map((audience) => audience.noticeType);
    if (noticeData) {
      this.form.patchValue(
        {
          id: noticeData.id,
          name: noticeData.name,
          description: noticeData.description,
          recurrence: noticeData.recurrence,
          alertType: types,
          startTimestamp: noticeData.startTimestamp,
          endTimestamp: noticeData.endTimestamp,
          url: noticeData.url,
          groups: noticeData.groups,
          campaignId: noticeData.campaignId,
          campaignName: noticeData.campaignName,
        }
      );
      this.gamificationService.setGamificationValues(
        this.gamificationFields,
        this.form,
        noticeData
      );

      this.updateCharacterCountDescription(noticeData.description);
      this.updateCharacterCountName(noticeData.name);

      if (noticeData.imageUrl) {
        await this.getImage(noticeData.imageUrl);
      }

      if (!this.isClientView) {
        this.clients = noticeData.clients!;
      } else {
        this.selectedClientsControl.setValue(noticeData.clients);
      }

      this.cdr.detectChanges();
      this.handleDisabledGrid();
    }

    if (!this.canEdit) this.form.disable();
  }

  handleSelectedListChange(selectedList: ClientDTO[]) {
    this.clients = selectedList;
  }

  markFormFieldsAsTouched() {
    Object.values(this.form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((innerControl) => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
  }

  async onSubmit() {
    if (this.isDisabled) return;
    const isEdit: boolean = this.form.valid && !!this.noticeId;
    const isRegister: boolean = this.form.valid && !this.noticeId;

    if (!this.validateDate()) return;

    if (this.form.valid) {
      try {
        const formData = this.form.value;
        const gamificationObject =
          this.gamificationService.getGamificationObject(
            this.gamificationFields,
            this.form
          );
        let noticeData;
        switch (true) {
          case isRegister && this.isClientView:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              type: this.form.get('alertType')?.value[0],
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clientId: this.clientUserId,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId,
              clients: this.selectedClientsControl.value,
            };
            break;

          case isRegister && !this.isClientView:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              targetAudience: formData.alertType.map((type: any) => ({
                noticeType: type,
              })),
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clients: this.clients,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId,
            };
            break;

          case isEdit && this.isClientView:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              type: this.form.get('alertType')?.value[0],
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clientId: this.clientUserId,
              groups: formData.groups,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId,
              clients: this.selectedClientsControl.value,
            };
            break;

          case isEdit && !this.isClientView:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              targetAudience: formData.alertType.map((type: any) => ({
                noticeType: type,
              })),
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clients: this.clients,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId,
            };
            break;

          default:
        }
        if (noticeData) {
          await this.handleNoticeOperation(noticeData);
        }
      } catch (err) {
        console.error(err);
      }
    } else if (!this.form.valid) {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );

      this.startDateFormInvalid =
        this.startDateFormInvalid ||
        this.form.get('startTimestamp')?.value == '';
      this.endDateFormInvalid =
        this.endDateFormInvalid || this.form.get('endTimestamp')?.value == '';
    }
  }

  validateDate(): boolean {
    const endDate = new Date(this.form.get('endTimestamp')?.value);
    const startDate = new Date(this.form.get('startTimestamp')?.value);
    const currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes(),
      0,
      0
    );
    const minDate = new Date(currentDate.getTime() + 5 * 60000);

    if (startDate && startDate < minDate) {
      this.toastComponent.showWarningCustomMessage(
        'Data e hora iniciais devem ser pelo menos 5 minutos maiores que a data e hora atuais.'
      );
      this.startDateFormInvalid = true;
      return false;
    }

    if ((endDate && endDate < startDate) || endDate < currentDate) {
      this.toastComponent.showWarningCustomMessage(
        'Data e hora finais devem ser maiores à data e hora iniciais.'
      );
      this.endDateFormInvalid = true;
      return false;
    }

    return true;
  }

  hasDescriptionOrImage(): boolean {
    let description = this.form.get('description')?.value;
    return !!this.image || !!description || description != '';
  }

  confirmDeleteNotice() {
    if (this.isDisabled) return;
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este aviso?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.removeNotice();
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Aviso excluído com sucesso!',
            3000
          );
        }
      });
  }

  async removeNotice() {
    try {
      if (this.noticeId) {
        if (this.isClientView) {
          await this.noticeService.deleteClientNotice(this.noticeId);
        } else {
          await this.noticeService.delete(this.noticeId);
        }
      }
      this.close();
    } catch (err) {
      console.error(err);
    }
  }

  async handleNoticeOperation(noticeData: any) {
    try {
      let promise;

      if (!this.noticeId) {
        promise = this.isClientView
          ? this.noticeService
              .registerClientNotice(noticeData, this.image)
              .then(() => {
                this.toastComponent.showSuccessCustomMessage(
                  'Sucesso!',
                  'Aviso criado com sucesso!',
                  3000
                );
              })
          : this.noticeService
              .registerNotice(noticeData, this.image)
              .then(() => {
                this.toastComponent.showSuccessCustomMessage(
                  'Sucesso!',
                  'Aviso criado com sucesso!',
                  3000
                );
              });
      } else {
        promise = this.isClientView
          ? this.noticeService
              .editClientNotice(noticeData, this.image)
              .then(() => {
                this.toastComponent.showSuccessCustomMessage(
                  'Sucesso!',
                  'Aviso editado com sucesso!',
                  3000
                );
              })
          : this.noticeService.edit(noticeData, this.image).then(() => {
              this.toastComponent.showSuccessCustomMessage(
                'Sucesso!',
                'Aviso editado com sucesso!',
                3000
              );
            });
      }

      await promise;
      localStorage.removeItem('selectedClients');
      this.close();
    } catch (err) {
      console.error(err);
    }
  }
  /* else {
   */
  twoDigits(value: number): string {
    // Adiciona um zero à esquerda se o valor for menor que 10
    return value < 10 ? `0${value}` : `${value}`;
  }
  maxDate(): string {
    // Defina o ano máximo como 9999 para limitar a quatro dígitos
    const maxYear = 9999;

    // Obtém a data atual
    const currentDate = new Date();

    // Define o ano máximo no formato ISO para o atributo 'max'
    const maxDateString = `${maxYear}-${this.twoDigits(
      currentDate.getMonth() + 1
    )}-${this.twoDigits(currentDate.getDate())}T${this.twoDigits(
      currentDate.getHours()
    )}:${this.twoDigits(currentDate.getMinutes())}`;

    return maxDateString;
  }

  minDate(): string {
    // Obtém a data e hora atual no formato adequado para datetime-local
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  minEndDate(): string {
    const startTimestampValue = this.form.get('startTimestamp')?.value;

    // Se não houver uma data inicial, retorna uma string vazia
    if (!startTimestampValue) {
      return '';
    }

    const startDate = new Date(startTimestampValue);

    const year = startDate.getFullYear();
    const month = ('0' + (startDate.getMonth() + 1)).slice(-2);
    const day = ('0' + startDate.getDate()).slice(-2);
    const hours = ('0' + startDate.getHours()).slice(-2);
    const minutes = ('0' + startDate.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  get hasPermissions() {
    if (!this.form.get('id')?.value && this.canCreate) return true;
    if (this.form.get('id')?.value && this.canEdit) return true;

    return false;
  }

  removeNoticeUserType(index: number): void {
    const alertTypesCtrl = this.form.get('alertType');
    const alertTypes = alertTypesCtrl?.value;
    alertTypes.splice(index, 1);
    alertTypesCtrl?.patchValue(alertTypes);
    this.handleDisabledGrid();
  }

  getNoticeLabelFromValue(value: any): string | undefined {
    return AlertUserTypesWithLabels?.find(
      (p) => p.value.toLocaleLowerCase() === value.toLocaleLowerCase()
    )?.label;
  }

  onSelectCampaign(event: CampaignComboboxResponseDTO | null) {
    if (!event) {
      this.toastComponent.showWarningCustomMessage(
        'Ao desvincular a campanha os pontos gerados por ela serão removidos.'
      );
    }
    this.form.get('campaignId')?.setValue(event?.id ?? null);
  }

  removeInvisibleChars(value: string): string {
    return this.globalFunctions.removeInvisibleChars(value);
  }

  close() {
    this.onClose.emit();
  }

  selectClientWithGroup(value: ClientsWithGroup[]) {
    this.selectedClientsControl.setValue(null);
    this.selectedClientsControl.setValue([...value]);
  }

  goNext(stepper: MatStepper, form: AbstractControl, invalidMessage?: string) {
    form.markAllAsTouched();

    if (form === this.form && !this.hasDescriptionOrImage()) {
      this.toastComponent.showWarningCustomMessage(
        'Por favor, adicione uma imagem ou insira uma descrição para continuar.'
      );
      return;
    }

    if (form.invalid) {
      this.toastComponent.showWarningCustomMessage(
        invalidMessage ?? 'Campo(s) obrigatório(s) não preenchido(s)!'
      );
      return;
    }

    stepper.next();
  }
}
