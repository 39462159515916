import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { from, map } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { Client } from 'src/app/shared/domains/client';
import { Contact } from 'src/app/shared/domains/contact';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

@Component({
  selector: 'app-client-user',
  templateUrl: './client-user.component.html',
  styleUrls: ['./client-user.component.scss']
})
export class ClientUserComponent implements OnInit {

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  dataSource = new MatTableDataSource<any>();
  contactDataSource = new MatTableDataSource<any>();


  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  @ViewChild("clientTableSort") clientTableSort: MatSort | undefined;

  displayedColumns: string[] = ['cnpj', 'corporateName', 'phone', 'email', 'isActive', 'actions'];
  contactDisplayedColumns: string[] = ['name', 'phone', 'role', 'email'];

  clients: Client[] = [];
  searchClientFormControl = new FormControl();
  lastFilterValue = '';
  fiterTable: string = '';
  dataModal: any = { isActive: false, data: '' };
  contactModalVisible = false;
  canDelete = true;
  canCreate = true;
  canEdit = true;
  canGoToSettings = false;
  paginationCard = 10;

  constructor(
    private toastComponent: ToastComponent,
    private router: Router,
    public globalFunctions: GlobalFunctions,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    public clientService: ClientService,
    private authService: AuthService,
    private userClientService: UserClientService,
    private renderer: Renderer2
  ) {
    from(this.getClients()).subscribe(() => { })

    this.searchClientFormControl.valueChanges
      .pipe(
        map((value: string) => value.trim().toLocaleLowerCase()),
        map((value: string) => value.trim().replace(/[.\-()/ ]/g, '').toLocaleLowerCase())
      )
      .subscribe((searchedValue) => this.applyFilter(searchedValue));
  }

  async ngOnInit() {
    this.canDelete = await this.rolesService.hasOnlyRoles('ADMIN');
    this.canCreate = await this.rolesService.hasOnlyRoles('ADMIN');
    this.canEdit = await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT);
    this.canGoToSettings = await this.permissionsService.hasPermission(ProfilePermissions.CONFIGURATION_INTEGRATION_CLIENT);
  }

  private applyFilter(filter: any) {
    const filterValue = filter.toLocaleLowerCase();
    if (!filterValue && this.lastFilterValue) {
      this.dataSource.data = this.clients;
    } else {
      this.dataSource.data = this.clients.filter((element: Client) =>
        element.cnpj.toLocaleLowerCase().includes(filter) ||
        element.corporateName.toLocaleLowerCase().includes(filter) ||
        element.phone.toLocaleLowerCase().includes(filter) ||
        element.email.toLocaleLowerCase().replace(/[.\-()/ ]/g, '').includes(filter) ||
        (element.isActive && filter.toLowerCase().startsWith('ati')) ||
        (!element.isActive && filter.toLowerCase().startsWith('ina'))
      );
    }
    this.lastFilterValue = filterValue;
  }

  configTable() {
    if (this.paginator)
      this.dataSource.paginator = this.paginator;
    if (this.clientTableSort) {
      this.dataSource.sort = this.clientTableSort;
    }
    this.dataSource.filter = this.fiterTable;
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToNewClient() {
    this.redirectTo('/clients/new-client');
  }

  redirectToEditClient(id?: number) {
    const extras: NavigationExtras = {
      queryParams: { id }
    }
    this.redirectTo('/clients/edit-client', extras);
  }

  redirectToViewClient(id?: number) {
    const extras: NavigationExtras = {
      queryParams: { id }
    }
    this.redirectTo('/clients/view-client', extras);
  }

  confirmDelete(client: Client) {
    this.confirmModal?.showModal('Deseja mesmo excluir este cliente?', 'Caso confirme, essa ação não poderá ser desfeita.')
      .subscribe(async isAccepted => {
        if (isAccepted) {
          const success = await this.clientService.delete(client);
          if (success) {
            this.updateTableAfterDelete(client.id)
            this.toastComponent.showSuccessCustomMessage(`Cliente excluído com sucesso`, '', 3000);
          }
        }
      });
  }

  updateTableAfterDelete(id: number | undefined) {
    this.clients.splice(this.clients.findIndex(c => c.id == id), 1);
    this.applyFilter(this.lastFilterValue);
  }

  async getClients() {
    let data: Client[] = [];
    const userCache = this.authService.getUserFromCache();
    if (userCache?.groups.some((x) => x.name === 'CLIENT')) {
      const result = await this.userClientService.getResumedUserClientByUserIdAndClientId(
        userCache?.id ?? 0
      );
      if (result.client.isAdministrator) {
        data = await this.clientService.getAllWithSubsidiary(result.client.id ?? 0 ?? 0);
      } else {
        data = await this.clientService.getAllWithSubsidiary(result.client.id ?? 0, false);
      }
    } else {
      data = await this.clientService.getAll();
    }

    this.clients = data;
    this.dataSource = new MatTableDataSource(data);
    this.configTable();
  }

  showContacts(contactId: number) {
    this.clientService.get(contactId).subscribe(data => {
      this.contactDataSource = new MatTableDataSource(this.getSortedContacts(data.contacts));
      this.contactModalVisible = true;
    });
  }

  redirectToConfigIntegration(clientId: number) {
    const extras: NavigationExtras = {
      queryParams: { id: clientId }
    }
    this.router.navigate(['/clients/config-integration'], extras);
  }

  getSortedContacts(contactList: Array<Contact>): Contact[] {
    let primaryContact: Contact[] = [];
    let secondaryContact: Contact[] = [];
    const defaultContacts: Contact[] = [];

    contactList.forEach(contact => {
      contact.contactApp = Contact.formatContactApp(contact.contactApp ?? "");
      if (contact.type === 'PRIMARY') {
        primaryContact.push(contact);
      } else if (contact.type === 'SECONDARY') {
        secondaryContact.push(contact);
      } else {
        defaultContacts.push(contact);
      }
    });

    const sortedContacts = [...primaryContact, ...secondaryContact, ...defaultContacts];
    return sortedContacts;
  }

  confirmChange(event: any, client: Client) {
    event.preventDefault();
    this.dataModal = { isActive: true, data: client.isActive ? 'inativar' : 'ativar' };

    this.confirmModal?.showModal(`Deseja mesmo ${this.dataModal.data} este cliente?`)
      .subscribe(async isAccepted => {
        if (isAccepted) {
          const success = await this.changeActiveClient(client);
        }
      });
  }

  async changeActiveClient(client: Client) {
    if (!this.canEdit || this.globalFunctions.isUserClient()) {
      const index = this.dataSource.data.findIndex((c: any) => c.id === client.id);
      const previousState = this.dataSource.data[index].isActive;
      this.dataSource.data[index].isActive = previousState;
      const checkbox = document.querySelectorAll('input[type="checkbox"].switchButton')[index] as HTMLInputElement;
      this.renderer.setAttribute(checkbox, 'disabled', 'true');
      this.toastComponent.showWarningCustomMessage(`Operação permitida somente para administradores.`, '');
      return;
    }
    client.isActive = !client.isActive;
    let success = false;
    if (client.id) success = await this.clientService.setActive(client.id, client.isActive);
    if (success) this.toastComponent.showSuccessCustomMessage(`Cliente ${client.isActive ? 'ativado' : 'desativado'} com sucesso`, '', 3000);
  }

}
