import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, map, Observable, Subject, take } from 'rxjs';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ClientDTO } from '../../domains/clientDto';
import { ClientService } from '../../services/client.service';
import { SearchColumnConfiguration } from '../search/interfaces/search-table-column-configuration.interface';

@Component({
  selector: 'app-select-client-modal',
  templateUrl: './select-client-modal.component.html',
  styleUrls: ['./select-client-modal.component.scss'],
})
export class SelectClientModalComponent implements OnInit {
  cancelButton: string = 'Cancelar';
  confirmButton: string = 'Confirmar';
  isEdit?: boolean = false;
  visible: boolean = false;

  columnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: '',
      isCheckbox: true,
      valueName: 'checked',
    },
    {
      columnName: 'Nome',
      valueName: 'name',
      columnValueCssClass: 'column-60',
      columnHeaderCssClass: 'column-60',
    },
  ];

  clientDataSource = new MatTableDataSource<any>();
  searchClientControl = new FormControl('');
  allClients: ClientDTO[] = [];

  get selectedClients(): number[] {
    return this.clientDataSource.data.filter((x) => x.checked).map((x) => x.id);
  }

  private resultSubject: Subject<number[] | null> = new Subject<
    number[] | null
  >();

  constructor(
    public globalFunctions: GlobalFunctions,
    private toastComponent: ToastComponent,
    private clientService: ClientService
  ) {
    this.searchClientControl.valueChanges
      .pipe(
        debounceTime(500),
        map((value: string) =>
          value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => {
        this.clientDataSource = new MatTableDataSource(
          this._mapClients(
            this.allClients.filter((element) =>
              element.corporateName.toLowerCase().includes(searchedValue)
            )
          )
        );
      });
  }
  ngOnInit(): void {}

  showModal(): Observable<number[] | null> {
    this.clientService.getClientsCombobox().then((value) => {
      this.allClients = value;
      this.clientDataSource = new MatTableDataSource(
        this._mapClients(value)
      );
      this.visible = true;
    })
    return this.resultSubject.asObservable().pipe(take(1));
  }

  private _mapClients(clients: ClientDTO[]) {
    return clients.map((client) => {
      return {
        ...client,
        name:
          (client.tradingName?.trim().length ?? 0) > 0
            ? client.tradingName
            : client.corporateName,
      };
    });
  }

  accept() {
    const selectedClients = this.selectedClients;
    if (selectedClients.length <= 0) {
      this.toastComponent.showWarningCustomMessage(
        'Selecione pelo menos uma empresa.'
      );
      return;
    }
    this.resultSubject.next(selectedClients);
    this.close();
  }

  reject() {
    this.resultSubject.next(null);
    this.close();
  }

  close() {
    this.searchClientControl.setValue('');
    this.clientDataSource = new MatTableDataSource();
    this.visible = false;
  }
}
