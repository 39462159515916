import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { debounceTime, from } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { SearchTableDeleteDialogConfiguration } from 'src/app/shared/components/search/interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from 'src/app/shared/components/search/interfaces/search-table-select-configuration.interface';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { User } from 'src/app/shared/domains/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NoticeService } from 'src/app/shared/services/notice.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

import { SelectClientGroupModalComponent } from 'src/app/shared/components/select-client-group-modal/select-client-group-modal.component';
import { LocalStorageKeys } from 'src/app/shared/enums/storage-keys';
import { Notice, NoticePage } from './../../../shared/domains/notice';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  clientSelectedFormControl = new FormControl();

  @ViewChild('myInput')
  myInputVariable: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('dataImportTableSort') dataImportTableSort: MatSort | undefined;
  @ViewChild('selectClientModal') selectClientModal:
    | SelectClientGroupModalComponent
    | undefined;
  searchFormControl = new FormControl();
  dataSource = new MatTableDataSource<any>();
  paginationCard: number = 10;
  displayedColumns: string[] = [
    'name',
    'startTimestamp',
    'endTimestamp',
    'recurrence',
    'actions',
  ];
  currentList: Notice[] | undefined = [];

  user: User = new User();
  currentListView: Notice[] | undefined = [];

  clientId: number | null = null;
  noticeId: number | null = null;
  registerMode: 'create' | 'view' | 'edit' = 'create';
  showNoticesCentral: boolean = false;

  pageSize: number = 10;
  pageLength!: number;
  pageIndex!: number;
  isSortingApplied: boolean = false;

  userCache: User | null | undefined;
  canDelete: boolean = false;
  canCreate: boolean = false;
  canEdit: boolean = false;

  columns: SearchColumnConfiguration[] = [];

  isClientView: boolean = true;

  openModalRegister: boolean = false;

  selectClient: SearchTableSelectConfiguration = {
    labelValueName: 'tradingName',
    data: [],
    placeholder: 'Cliente',
    disableAddButtonWhileNotSelect: false,
  };

  dialogInfo: SearchTableDeleteDialogConfiguration = {
    onConfirmFn: this.deleteNotice.bind(this),
    title: 'Deseja mesmo excluir este Aviso?',
    description:
      'Caso confirme, essa ação não poderá ser desfeita e serão removidos os pontos gerados pela campanha.',
  };

  constructor(
    private router: Router,
    public globalFunctions: GlobalFunctions,
    private noticeService: NoticeService,
    private toastComponent: ToastComponent,
    private authService: AuthService,
    private userClientService: UserClientService,
    private datePipe: DatePipe,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) {
    from(this.rolesService.hasOnlyRoles('ADMIN')).subscribe((isAdmin) => {
      this.isClientView = !isAdmin;
    });

    this.searchFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((searchText: string) => {
        this.pageIndex = 0;
        if (!searchText) {
          this.getList();
          this.getAllNotices(0, this.pageSize, ['']);
        } else {
          this.getAllNotices(0, this.pageSize, [searchText]);
        }
      });

    this.clientSelectedFormControl.valueChanges.subscribe(
      (client: ClientDTO) => {
        this.clientId = client.id;
        this.getAllNotices(0, 10, [
          this.searchFormControl.value ? this.searchFormControl.value : '',
        ]);
      }
    );
  }

  openNoticeCentral(notice: Notice) {
    this.noticeId = notice.id!;
    this.showNoticesCentral = true;
  }

  async removeNotice(id: number) {
    try {
      if (this.clientId) {
        await this.noticeService.deleteClientNotice(id);
      } else {
        await this.noticeService.delete(id);
      }
    } finally {
      this.getAllNotices(0, 10, [
        this.searchFormControl.value ? this.searchFormControl.value : '',
      ]);
    }
  }

  convertRecurrence(data: string) {
    if (data === 'ONCE') {
      return 'Única';
    }
    if (data === 'DAILY') {
      return 'Diária';
    }
    if (data === 'WEEKLY') {
      return 'Semanal';
    } else {
      return 'Mensal';
    }
  }

  async confirmDeleteNotice(id: number) {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este aviso?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.removeNotice(id);
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Aviso excluído com sucesso!',
            3000
          );
        }
      });
  }

  private async deleteNotice(notice: any) {
    if (!notice.canEdit) return;
    try {
      if (notice.id) {
        if (this.clientId) {
          await this.noticeService.deleteClientNotice(notice.id);
        } else {
          await this.noticeService.delete(notice.id);
        }
      }
    } finally {
      this.toastComponent.showSuccessCustomMessage(
        'Sucesso!',
        'Aviso deletado com sucesso!',
        3000
      );

      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator?.firstPage();
      this.pageLength = 10;
      this.pageIndex = 0;
      this.configTable();

      this.getAllNotices(0, 10, [
        this.searchFormControl.value ? this.searchFormControl.value : '',
      ]);
    }
  }

  async getAllNotices(page: number, pageSize: number, sort: string[]) {
    try {
      const params: paramsProps = {
        page: page,
        size: pageSize,
        sort: [' '],
        name: sort[0],
      };
      if (this.clientId) {
        params.clientId = this.clientId;
        const noticesList = await this.noticeService.getAllClientNotices(
          params
        );
        this.afterLoadConfig(noticesList);
      } else {
        const noticesList = await this.noticeService.getAllNotices(params);
        this.afterLoadConfig(noticesList);
      }
    } catch (err) {
      console.error(err);
    }
  }

  afterLoadConfig(notices: NoticePage) {
    this.currentListView = notices.content;
    this.dataSource = new MatTableDataSource(this.mapperNotice());
    this.dataSource.paginator?.firstPage();

    this.pageLength = notices.totalElements;
    this.pageIndex = notices.number;
    this.configTable();
  }

  sortingDataAccessorMethod = (item: any, property: any) => {
    this.isSortingApplied = true;

    this.dataSource = new MatTableDataSource(this.mapperNotice());
    this.dataSource.sortingDataAccessor = (item, property) => {
      const value = item[property];
      if (!isNaN(value) && typeof value !== 'boolean') {
        return value;
      }
      return String(value).toLowerCase();
    };
  };

  removeSorting = (data: Sort) => {
    if (data.direction === '') {
      this.dataSource = new MatTableDataSource(this.mapperNotice());
    }
  };

  initColumns() {
    this.columns = [
      {
        columnName: 'Nome',
        valueName: 'name',
        columnValueCssClass: 'column-30 column-category-20 truncate-words',
      },
      {
        columnName: 'Data e Hora Início',
        valueName: 'startTimestamp',
        columnValueCssClass: 'column-20 column-category-20 truncate-words',
      },
      {
        columnName: 'Data e Hora Fim',
        valueName: 'endTimestamp',
        columnValueCssClass: 'column-20 column-category-20 truncate-words',
      },
      {
        columnName: 'Recorrência',
        valueName: 'recurrence',
        columnValueCssClass: 'column-20 column-category-20 truncate-words',
      },
      {
        actionsConfiguration: {
          useEditButton: this.canEdit,
          useViewButton: true,
          useDeleteFeature: true,
          disableEditIcon: 'canEdit',
          disableDeleteIcon: 'canEdit',
          extraActions: [
            {
              iconName: 'gamification',
              tooltip: 'Esse aviso gera pontos na sua visualização.',
              booleanValueName: 'hasGamification',
              onClick: () => null,
            },
            {
              iconName: 'campaign',
              tooltip: 'Central de Avisos',
              onClick: this.openNoticeCentral.bind(this),
            },
          ],
        },
        columnName: '',
        valueName: 'actions',
        columnValueCssClass: 'd-flex justify-content-end',
      },
    ];
  }

  async getPermissions() {
    this.canDelete = await this.permissionsService.hasPermission(
      this.clientId
        ? ProfilePermissions.DELETE_CLIENT_NOTICES
        : ProfilePermissions.DELETE_NOTICES
    );
    this.canCreate = await this.permissionsService.hasPermission(
      this.clientId
        ? ProfilePermissions.CREATE_CLIENT_NOTICES
        : ProfilePermissions.CREATE_NOTICES
    );
    this.canEdit = await this.permissionsService.hasPermission(
      this.clientId
        ? ProfilePermissions.UPDATE_CLIENT_NOTICES
        : ProfilePermissions.UPDATE_NOTICES
    );

    this.initColumns();
  }

  async ngOnInit() {
    if (this.clientId === null) {
      const userCache = this.authService.getUserFromCache();
      if (userCache) {
        this.user = userCache;
      }
      if (this.user.groups.some((x) => x.name === 'CLIENT')) {
        await this.getClientData();
      } else if (this.clientId === null) {
        this.getAllNotices(0, 10, [
          this.searchFormControl.value ? this.searchFormControl.value : '',
        ]);
      }
    }
    await this.getPermissions();
  }

  async getClientData() {
    try {
      const result =
        await this.userClientService.getResumedUserClientByUserIdAndClientId(
          this.user?.id ?? 0
        );
      this.clientId = result.client.id;
    } catch (err) {
      console.error(err);
    }
  }

  onPageChangeView(event: PageEvent) {
    const page = event.pageIndex + 0;
    const pageSize = event.pageSize;
    this.pageSize = event.pageSize;
    try {
      if (page == 0) this.pageIndex = 0;

      this.getAllNotices(page, pageSize, [
        this.searchFormControl.value ? this.searchFormControl.value : '',
      ]);
    } finally {
      this.dataSource = new MatTableDataSource(this.mapperNotice());
    }
  }

  ngAfterViewInit(): void {}

  getList() {
    this.dataSource = new MatTableDataSource(this.mapperNotice());
    this.dataSource.paginator?.firstPage();
  }

  private configTable() {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }

    if (this.dataImportTableSort) {
      this.dataSource.sort = this.dataImportTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        return item[property]?.toLowerCase();
      };
    }
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToEdit(notice: any) {
    if (!notice.canEdit) return;
    this.noticeId = notice.id!;
    this.registerMode = 'edit';
    this.openModalRegister = true;
  }

  redirectToView(notice: Notice) {
    this.noticeId = notice.id!;
    this.registerMode = 'view';
    this.openModalRegister = true;
  }

  formatTimestamp(timestamp: string | null): string {
    if (timestamp) {
      return this.datePipe.transform(timestamp, 'dd/MM/yyyy HH:mm:ss') || '';
    } else {
      return '';
    }
  }

  private mapperNotice(): Notice[] {
    return this.currentListView!.map(
      (n) =>
        ({
          ...n,
          recurrence: this.convertRecurrence(n.recurrence),
          startTimestamp: this.formatTimestamp(n.startTimestamp),
          endTimestamp: this.formatTimestamp(n.endTimestamp),
          canEdit:
            !this.isClientView ||
            n.clientId ===
              parseInt(
                localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID)!
              ),
        } as Notice)
    );
  }

  async getNoticesByClient(clientId: number) {
    try {
      this.clientId = clientId;
      const params: paramsProps = {
        page: 0,
        size: 10,
        sort: [''],
        name: '',
        clientId: this.clientId,
      };
      const noticesList = await this.noticeService.getAllClientNotices(params);
      this.afterLoadConfig(noticesList);
    } catch (err) {
      console.error(err);
    }
  }

  applyFilter(string: string) {
    this.searchFormControl.setValue(string);
  }

  redirectToNew() {
    this.noticeId = null;
    this.registerMode = 'create';
    this.openModalRegister = true;
  }

  getNoticesByMobile() {
    this.paginationCard += 10;
    this.getAllNotices(0, this.paginationCard, [
      this.searchFormControl.value ? this.searchFormControl.value : '',
    ]);
  }

  close() {
    this.openModalRegister = false;
    if (!!this.clientSelectedFormControl.value)
      this.getAllNotices(this.pageIndex, this.pageSize, [
        this.searchFormControl.value ? this.searchFormControl.value : '',
      ]);
  }
}

export interface paramsProps {
  page: number;
  size: number;
  sort: string[];
  name: string;
  clientId?: number;
}
