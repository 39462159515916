<p-dialog
  [(visible)]="visible"
  [style]="{ width: '1300px', height: '700px' }"
  class="medium-l"
  (onHide)="close()"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [formGroup]="form"
  [ngClass]="{ 'medium-mobile': globalFunctions.isMobile() }"
>
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="pl-2 subtitle subtitle-2 mt-2">
      <b>
        {{
          registerMode === "edit"
            ? "Editar Aviso"
            : registerMode === "view"
            ? "Visualizar Aviso"
            : "Novo Aviso"
        }}
      </b>
    </span>
  </ng-template>

  <mat-stepper
    [linear]="true"
    labelPosition="bottom"
    #stepper
    [orientation]="(stepperOrientation | async)!"
    [style]="globalFunctions.isMobile() ? 'margin-left: -30px;' : ''"
  >
    <mat-step
      [stepControl]="selectedClientsControl"
      [completed]="selectedClientsControl.valid"
    >
      <ng-template matStepLabel><b>Destinatários</b></ng-template>
      <form>
        <app-select-client
          *ngIf="isClientView; else clientList"
          [selectedGroups]="this.selectedClientsControl.value"
          (onChangeGroups)="selectClientWithGroup($event)"
          [disabled]="isDisabled"
        >
        </app-select-client>

        <ng-template #clientList>
          <app-client-list
            [disabled]="isDisabledGrid"
            [clients]="clients"
            (selectedListEmitter)="handleSelectedListChange($event)"
          ></app-client-list>
        </ng-template>
      </form>
      <div class="buttons">
        <div>
          <button
            (click)="
              goNext(
                stepper,
                selectedClientsControl,
                'Por favor, selecione ao menos um destinatário para continuar.'
              )
            "
            mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white"
          >
            Próximo
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="form" [completed]="form.valid">
      <ng-template matStepLabel><b>Configuração</b></ng-template>
      <form
        #eventForm="ngForm"
        class="container-fluid"
        [formGroup]="form"
        style="margin-top: 20px"
      >
        <div class="form-group row">
          <div class="col-12 col-md-6">
            <app-input-data-hour-picker
              [required]="true"
              [initialDate]="form.get('startTimestamp')?.value"
              [formInvalid]="startDateFormInvalid"
              [label]="'Data e Hora Iniciais'"
              (dateSelected)="
                form.get('startTimestamp')?.setValue($event);
                handleChangeStartDate()
              "
              [disabled]="isDisabled"
            >
            </app-input-data-hour-picker>
          </div>
          <div class="col-12 col-md-6">
            <app-input-data-hour-picker
              [required]="true"
              [initialDate]="form.get('endTimestamp')?.value"
              [formInvalid]="endDateFormInvalid"
              [label]="'Data e Hora Finais'"
              (dateSelected)="
                form.get('endTimestamp')?.setValue($event);
                handleChangeEndDate()
              "
              [disabled]="isDisabled"
            >
            </app-input-data-hour-picker>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>Nome</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              ngModel
              required
              (input)="updateCharacterCountName($any($event.target).value)"
              maxlength="255"
            />
          </mat-form-field>
          <div
            class="d-flex justify-content-end character-count text-area-counter"
          >
            {{ characterCountName }}/255
          </div>
        </div>
        <div class="form-group">
          <mat-form-field
            appearance="outline"
            class="col-12 col-md-6 pl-md-0 selectStyle mat-input mat-chip-area"
          >
            <mat-label>Público-alvo</mat-label>

            <mat-select
              formControlName="alertType"
              ngModel
              required
              (selectionChange)="handleDisabledGrid()"
              multiple
              class="multi-select"
            >
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip
                    *ngFor="
                      let notice of form.get('alertType')?.value;
                      let index = index
                    "
                    [disabled]="isClientView"
                    class="custom-label-input"
                    (removed)="removeNoticeUserType(index)"
                  >
                    {{ getNoticeLabelFromValue(notice) }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option *ngFor="let type of userTypes" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="col-12 col-md-6 pr-md-0 selectStyle"
          >
            <mat-label>Recorrência</mat-label>
            <mat-select formControlName="recurrence" ngModel required>
              <mat-option value="DAILY">Diária</mat-option>
              <mat-option value="WEEKLY">Semanal</mat-option>
              <mat-option value="MONTHLY">Mensal</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group"></div>
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>URL</mat-label>
            <input matInput type="text" formControlName="url" ngModel />
          </mat-form-field>
        </div>
        <div class="form-group text-area">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>Descrição</mat-label>
            <textarea
              matInput
              formControlName="description"
              ngModel
              rows="3"
              matInput
              maxLength="400"
              cdkTextareaAutosize
              (input)="
                updateCharacterCountDescription($any($event.target).value)
              "
            ></textarea>
          </mat-form-field>
          <div
            class="d-flex justify-content-end character-count text-area-counter"
          >
            {{ characterCountDescription }}/400
          </div>
        </div>
        <div class="col-12 col-md-6 imageBox" style="padding: 0px !important">
          <div class="col-12 col-md-10">
            <div class="anexo-area">
              <div class="margin-15">
                <div class="row">
                  <div class="col-12 d-flex align-items-center">
                    <label
                      class="subtitle subtitle-2 semibold extended-secondary-darker"
                      >Imagem</label
                    >
                    <div class="ml-2">
                      <app-custom-tooltip
                        [text]="
                          'Formatos: JPEG e PNG.\nTamanho máximo: 15MB.\nDimensões: 1080px x 1350px.'
                        "
                        [tooltipStyleClass]="'info-att-img'"
                        [tooltipPosition]="'top'"
                        [styleTooltip]="
                          'margin-top: -3rem; height: fit-content'
                        "
                        [styleResponsive]="
                          'margin-left: -20vw; width: 85vw; top: -10vh !important;'
                        "
                      >
                      </app-custom-tooltip>
                    </div>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-8">
                    <div
                      class="container-img"
                      (dragover)="!isDisabled ? onDragOver($event) : ''"
                      (drop)="!isDisabled ? onDrop($event) : ''"
                    >
                      <div
                        class="anexo-area"
                        style="
                          min-width: 100%;
                          margin-bottom: -20px;
                          padding-bottom: 20px;
                        "
                      >
                        <div class="col-12">
                          <div
                            class="row justify-content-end"
                            *ngIf="selectedImage"
                          >
                            <mat-icon
                              class="icon-file click"
                              (click)="!isDisabled ? removeImg(imgInput) : ''"
                              >close</mat-icon
                            >
                          </div>
                        </div>
                        <div
                          class="img-anexo"
                          style="margin-bottom: 20px; margin-top: -10px"
                        >
                          <img
                            *ngIf="!selectedImage"
                            src="../../../../assets/img/images.png"
                            alt="Imagem do aviso"
                          />
                          <img
                            *ngIf="selectedImage"
                            class="click"
                            (click)="
                              downloadImage(selectedImage, getFileName())
                            "
                            [src]="sanitizeImageUrl(selectedImage)"
                            alt="Imagem selecionada"
                            class="img-responsive"
                          />
                        </div>
                      </div>
                      <div
                        class="col-12 text-center"
                        *ngIf="!selectedImage"
                        style="margin-top: -25px"
                      >
                        <label class="pass-13 neutral-ligh"
                          >Arraste a imagem aqui ou</label
                        >
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <button
                            mat-button
                            class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                            (click)="!isDisabled ? imgInput.click() : ''"
                            [disabled]="isDisabled"
                          >
                            Selecione o arquivo</button
                          >&nbsp;
                          <input
                            type="file"
                            multiple
                            #imgInput
                            style="display: none"
                            (change)="onImgAdded($event)"
                            accept="image/jpeg, image/png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="buttons" *ngIf="isClientView; else finalButtons">
        <div>
          <button
            mat-button
            matStepperPrevious
            class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          >
            Voltar
          </button>
        </div>
        <div>
          <button
            (click)="goNext(stepper, form)"
            mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white"
          >
            Próximo
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step *ngIf="isClientView">
      <ng-template matStepLabel><b>Gamificação</b></ng-template>
      <form class="container-fluid" [formGroup]="form">
        <div style="margin-top: 30px">
          <div class="row">
            <div class="col-12">
              <app-campaign-select
                (onSelectCampaign)="onSelectCampaign($event)"
                [selectedCampaignName]="form.get('campaignName')?.value"
                [disabled]="isDisabled"
              ></app-campaign-select>
            </div>
          </div>
          <div style="width: 4rem; margin-left: auto">
            <mat-checkbox id="gamification" formControlName="isGeral">
              <label
                for="gamification"
                class="paragraph paragraph-2 label-checkbox click"
                >Geral</label
              >
            </mat-checkbox>
          </div>
          <article
            class="d-flex justify-content-between"
            *ngFor="let field of gamificationFields"
          >
            <div class="d-flex align-items-center">
              <input
                type="checkbox"
                name="togle"
                [id]="field.key"
                class="switchButton"
                [formControlName]="
                  gamificationService.getAllowFieldName(field.key)
                "
              />
              <label [for]="field.key" class="label-st">
                <div>
                  <p class="mb-1">
                    <b>{{ field.value }}</b>
                  </p>
                </div>
              </label>
            </div>
            <mat-form-field
              appearance="outline"
              class="mat-input"
              style="max-width: 17rem"
            >
              <mat-label class="paragraph paragraph-2 mat-label"
                >Pontos</mat-label
              >
              <input
                matInput
                [formControlName]="
                  gamificationService.getValueFieldName(field.key)
                "
                mask="separator.0"
                thousandSeparator=""
                [allowNegativeNumbers]="true"
              />
            </mat-form-field>
          </article>
        </div>
      </form>
      <ng-container *ngTemplateOutlet="finalButtons"></ng-container>
    </mat-step>
    <ng-template #finalButtons>
      <div class="buttons">
        <div>
          <button
            class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
            (click)="confirmDeleteNotice()"
            [disabled]="isDisabled"
            *ngIf="noticeId && !globalFunctions.isMobile()"
          >
            <mat-icon class="icon-btn">delete</mat-icon>Excluir Aviso
          </button>
        </div>
        <div>
          <button
            mat-button
            matStepperPrevious
            class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          >
            Voltar
          </button>
        </div>
        <div>
          <button
            class="btn btn-primary subtitle subtitle-1 semibold white"
            (click)="onSubmit()"
            [disabled]="isDisabled"
          >
            Salvar
          </button>
        </div>
      </div>
    </ng-template>
  </mat-stepper>

  <app-confirm-modal #confirmModal></app-confirm-modal
></p-dialog>
