import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, map } from 'rxjs';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss']
})
export class UserProfileModalComponent implements OnInit {
  @Input()
  visible: boolean = false;

  @Input()
  selectedProfileId: number = 0;

  @Input()
  selectedClientId: number = 0;

  @Output()
  onClose = new EventEmitter<boolean>();

  dataSource = new MatTableDataSource<any>();
  dataImportTableSort: MatSort | undefined;
  pageLength!: number;
  pageIndex!: number;
  pageSize: number = 10;

  columnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: 'Nome',
      valueName: 'userName',
      columnValueCssClass: 'column-90',
      columnHeaderCssClass: 'column-90',
    },
    {
      columnName: 'Situação',
      valueName: 'status',
      toggleConfiguration: {
        valueName: 'status',
        disable: 'userName'
      },
      columnValueCssClass: 'column-10'
    }
  ];

  searchUserControl = new FormControl('');

  constructor(
    public globalFunctions: GlobalFunctions, 
    private profileService: ProfileService
  ) {
    this.searchUserControl.valueChanges.pipe(
      debounceTime(1000),
      map((value: string) =>
        value.trim().replace(/[.-]/g, '').toLocaleLowerCase()
      )
    ).subscribe((searchedValue) => {
      this.getUsers([searchedValue], this.pageSize, 0);
    });
  }

  ngOnInit() {
    this.getUsers(
      this.searchUserControl.value ? this.searchUserControl.value : '', this.pageSize, 0);
  }

  async getUsers(filter: string[], pageSize: number, page: number) {
    this.dataSource = new MatTableDataSource();
    const users = await this.profileService.getFilteredUsersByProfile({
      profileId: this.selectedProfileId,
      clientId: this.selectedClientId,
      filter: filter,
      page: page,
      pageSize: pageSize
    });

    this.dataSource = new MatTableDataSource(users.content);
    this.dataSource.paginator?.firstPage();
    this.pageLength = users.totalElements;
    this.pageIndex = users.number;
    this.configTable();
  }

  private configTable() {
    if (this.dataImportTableSort) {
      this.dataSource.sort = this.dataImportTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        return item[property]?.toLowerCase();
      };
    }
  }

  async onPageChange(event: PageEvent) {
    const page = event.pageIndex + 0;
    const pageSize = event.pageSize;
    this.pageSize = event.pageSize;

    if(page == 0) this.pageIndex = 0;

    this.getUsers(
      [this.searchUserControl.value ? this.searchUserControl.value : ''],
      pageSize,
      page
    );
  }
  
  close() {
    this.onClose.emit();
  }
}
