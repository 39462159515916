<section style="margin-top: 20px">
  <div class="col-12" *ngFor="let client of data">
    <header>
      <button
        mat-icon-button
        (click)="client.collapse = !client.collapse"
        style="margin-top: -10px"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ !client.collapse ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <h6 (click)="client.collapse = !client.collapse">{{ client.name }}</h6>
    </header>
    <article class="groups" [@collapse]="client.collapse">
      <div>
        <mat-checkbox
          [disabled]="disabled"
          [checked]="client.allGroups"
          (change)="onSelectAllGroups(client.id)"
        >
          <mat-label class="paragraph paragraph-2 click">
            Todos os usuários do APP
          </mat-label>
        </mat-checkbox>
      </div>
      <div *ngFor="let group of client.groups">
        <mat-checkbox
          [disabled]="disabled || client.allGroups"
          [checked]="group.checked"
          (change)="onSelectGroup(group, client.id)"
        >
          <mat-label class="paragraph paragraph-2 click">
            {{ group.name }}
          </mat-label>
        </mat-checkbox>
      </div>
    </article>
    <div class="groups container-fluid d-sm-none" [@collapse]="client.collapse">
      <div style="margin: 0px 10px 10px 10px">
        <div class="row">
          <div class="col-12">
            <ng-container>
              <div class="mobile-session">
                <div
                  class="card-mobile"
                  [ngClass]="client.allGroups ? 'card-selected' : ''"
                  (click)="onSelectAllGroups(client.id)"
                >
                  <div class="margin-btm">
                    <label class="paragraph paragraph-1 semibold truncate-line"
                      >Todos os usuários do APP</label
                    >
                  </div>
                </div>
              </div>
              <div
                class="mobile-session"
                *ngFor="let group of client.groups; let i = index"
              >
                <div
                  class="card-mobile"
                  [ngClass]="{
                    'card-selected': group.checked,
                    disabled: disabled || client.allGroups
                  }"
                  (click)="
                    disabled || client.allGroups
                      ? ''
                      : onSelectGroup(group, client.id)
                  "
                >
                  <div class="margin-btm">
                    <label
                      class="paragraph paragraph-1 semibold truncate-line"
                      >{{ group.name }}</label
                    >
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
