import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private showLoadingSource: Subject<boolean> = new Subject<boolean>()

  public get showLoading$() {
    return this.showLoadingSource.asObservable();
  }

  showLoading() {
    this.showLoadingSource.next(true);
  }

  hideLoading() {
    this.showLoadingSource.next(false);
  }
}