import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'primeng/dialog';
import { NoticeViewModalComponent } from './notice-view-modal.component';

@NgModule({
  declarations: [NoticeViewModalComponent],
  exports: [NoticeViewModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    DialogModule,
  ],
})
export class NoticeViewModalModule {}
