import { ProfileWithLabels } from './../../domains/auth/auth-persona-response.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthProfile, ProfileType } from '../../domains/auth/auth-persona-response.interface';
import { LocalStorageKeys } from '../../enums/storage-keys';
import { AuthServiceV2 } from '../../services/auth-v2/auth-v2.service';

@Component({
  selector: 'app-persona-select',
  templateUrl: './persona-select.component.html',
  styleUrls: ['./persona-select.component.scss']
})
export class PersonaSelectComponent implements OnInit {

  @Input()
  tooltipLayout: boolean = false

  profiles: AuthProfile[] = []

  selectedProfile: AuthProfile | null = null

  _keepConnected?: boolean

  ProfileWithLabels = ProfileWithLabels

  blockProfiles: boolean = false

  get keepConnected(): boolean {
    if (this._keepConnected) return this._keepConnected
    const keepConnected = localStorage.getItem(LocalStorageKeys.WRH_CACHE_KEEP_CONNECTED) ?? 'false'
    return JSON.parse(keepConnected);
  }

  @Input()
  set keepConnected(keepConnected: boolean) {
    this._keepConnected = keepConnected;
  }

  constructor(private authService: AuthServiceV2) { }

  async ngOnInit() {
    this._updateProfiles();
    this.authService.profileUpdated.subscribe(() => {
      this._updateProfiles();
    })
  }

  private _updateProfiles() {
    this.selectedProfile = this.authService.getLocalSelectedProfile();
    this.profiles = this.authService.getLocalProfiles()?.filter(
      profile => {
        return this.selectedProfile === null
          || profile.clientId !== this.selectedProfile.clientId
      }) ?? [];
  }

  async selectProfile(profile: AuthProfile) {
    this.blockProfiles = true

    try {
      await this.authService.authLogin(profile, this.keepConnected)
    } finally {
      this.blockProfiles = false
    }
  }
}
