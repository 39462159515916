<div class="profile-selection" [class]="!tooltipLayout ? 'login-like' : ''" *ngIf="profiles?.length! > 0 ?? false">
  <label *ngIf="!tooltipLayout && !blockProfiles" for="" class="titles title-2 primary-color">
    Selecione com qual usuário/empresa deseja se logar:
  </label>
  <label *ngIf="!tooltipLayout && blockProfiles" for="" class="titles title-2 primary-color">
    Usuário/empresa selecionado. Você será redirecionado.
  </label>
  
  <div class="button-group" *ngIf="!blockProfiles">
    <button *ngFor="let profile of profiles" mat-button [style]="!tooltipLayout ? 'width: 300px;' : 'width: 200px;'"
      class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" (click)="selectProfile(profile)">
      {{ profile.clientName ?? 'Administrador' }}
    </button>
  </div>
</div>