<div class="container-fluid" [formGroup]="form">
  <div class="margin-15">
    <div class="d-flex align-items-center justify-content-between">
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="!isEditionMode">
        Novo Perfil
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="isEditionMode && canEdit">
        Editar Perfil
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="isEditionMode && !canEdit">
        Visualizar Perfil
      </label>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Cliente</mat-label>
          <input matInput placeholder="" formControlName="clientName" />
        </mat-form-field>
      </div>

      <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
        <div class="paragraph paragraph-2 switchPosition">
          <label class="pass-13 secondary-neutral">Situação</label>
        </div>
        <div class="d-flex align-items-center">
          <input
            type="checkbox"
            name="togle"
            id="togle"
            class="switchButton"
            formControlName="isActive"
            [style]="globalFunctions.isMobile() ? 'margin-bottom: 15px' : ''"
          />
          <label for="togle" class="label-st">{{
            form.get("isActive")?.value ? "Ativo" : "Inativo"
          }}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Nome</mat-label>
          <input
            matInput
            placeholder=""
            formControlName="name"
            (input)="limitInputLength($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-area">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label"
            >Descrição</mat-label
          >
          <textarea
            matInput
            formControlName="description"
            maxLength="400"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="paragraph paragraph-2 semibold extended-secondary-darker"
          >Permissões</label
        >
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label"
            >Pesquisar</mat-label
          >
          <input matInput value="" [formControl]="searchControl" />
        </mat-form-field>

        <div class="check-list">
          <div
            class="col-12"
            [ngClass]="
              globalFunctions.isMobile() ? 'scroll-auto-mobile' : 'scroll-auto'
            "
          >
            <ng-container *ngFor="let role of filteredRoles">
              <div *ngIf="showRole(role)">
                <mat-checkbox
                  [disabled]="cannotEditOrCreate"
                  [checked]="role.selected"
                  (change)="toggle(role)"
                >
                  <label
                    class="paragraph paragraph-2 label-checkbox click"
                    (click)="!cannotEditOrCreate && toggle(role)"
                    >{{ role.description }}</label
                  >
                </mat-checkbox>
                <div class="authorities">
                  <ng-container *ngFor="let auth of role.authorities">
                    <div *ngIf="auth.visible">
                      <div style="margin-bottom: 5px">
                        <mat-checkbox
                          [checked]="auth.selected"
                          (change)="toggleAuth(role, auth)"
                          [disabled]="auth.disabled || cannotEditOrCreate"
                        >
                          <label
                            class="paragraph paragraph-2 label-checkbox"
                            [class]="
                              auth.disabled || cannotEditOrCreate
                                ? 'not-allowed'
                                : 'click'
                            "
                            (click)="
                              !(auth.disabled || cannotEditOrCreate) &&
                                toggleAuth(role, auth)
                            "
                          >
                          {{ auth.description }}
                          </label>
                        </mat-checkbox>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-sm-6"
        [style]="globalFunctions.isMobile() ? 'margin-top: 20px' : ''"
      >
        <label class="paragraph paragraph-2 semibold extended-secondary-darker"
          >Usuários</label
        >
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label"
            >Pesquisar</mat-label
          >
          <input
            matInput
            value=""
            [formControl]="searchControlUser"
            [disabled]="!canAssociateProfiles || !form.get('isActive')?.value"
          />
        </mat-form-field>

        <div class="check-list">
          <div
            class="col-12"
            [ngClass]="
              globalFunctions.isMobile() ? 'scroll-auto-mobile' : 'scroll-auto'
            "
          >
            <div style="margin-bottom: 10px" *ngFor="let user of filteredUsers">
              <mat-checkbox
                [disabled]="
                  !canAssociateProfiles || !form.get('isActive')?.value
                "
                (change)="onCheckboxChange(user)"
                [checked]="user.selected"
              >
                <label
                  class="paragraph paragraph-2 label-checkbox click"
                  (click)="(!canAssociateProfiles)"
                  >{{ user.name }}</label
                >
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!globalFunctions.isMobile()" style="margin-top: 10px;">
      <div class="col-sm-2">
        <button *ngIf="isEditionMode" mat-button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" [disabled]="!canDelete" (click)="confirmDelete()">
          <mat-icon class="icon-btn">delete</mat-icon> Excluir Perfil
        </button>
      </div>
      <div class="col-sm-6"></div>
      <div class="col-sm-2">
        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color" (click)="confirmCancel()">
          Cancelar
        </button>&nbsp;
      </div>
      <div class="col-sm-2">
        <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="saveOrEdit()" [disabled]="!hasPermissions">
          Salvar
        </button>&nbsp;
      </div>
    </div>
    


    <div
      *ngIf="globalFunctions.isMobile()"
      class="mt-5 d-flex justify-content-between"
    >
      <div>
        <div class="paragraph paragraph-2 switchPosition">
          <label class="pass-13 secondary-neutral">Situação</label>
        </div>
        <div class="d-flex align-items-center">
          <input
            type="checkbox"
            name="togle"
            id="togle"
            class="switchButton"
            formControlName="isActive"
          />
          <label for="togle" class="label-st">{{
            form.get("isActive")?.value ? "Ativo" : "Inativo"
          }}</label>
        </div>
      </div>

      <div class="d-flex align-items-end" *ngIf="isEditionMode">
        <button
          mat-mini-fab
          (click)="confirmDelete()"
          [disabled]="!canDelete"
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color bg-white"
        >
          <mat-icon class="icon-btn ml-0 material-icons-outlined"
            >delete</mat-icon
          >
        </button>
      </div>
    </div>

    <div
      *ngIf="globalFunctions.isMobile()"
      class="d-block justify-content-between mt-4"
    >
      <div class="row">
        <div class="col-12 col-md-8">
          <button
            mat-button
            class="btn btn-primary subtitle subtitle-1 semibold white"
            (click)="saveOrEdit()"
            [disabled]="!hasPermissions"
          >
            Salvar</button
          >&nbsp;
        </div>
        <div class="col-12 col-md-8">
          <button
            mat-button
            class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
            (click)="confirmCancel()"
          >
            Cancelar</button
          >&nbsp;
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>
