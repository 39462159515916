<p-dialog [(visible)]="visible" [style]="{width: '700px', height: '700px'}" class="medium-l" (onHide)="close()"
  [modal]="true" [draggable]="false" [resizable]="false"
  [ngClass]="{'medium-mobile': globalFunctions.isMobile()}">
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="pl-2 subtitle subtitle-2 mt-2">
      <b>
        Selecione a(s) empresa(s) desejada(s)
      </b>
    </span>
  </ng-template>
  <div class="row">
    <div class="col-12">
        <app-search-table
          *ngIf="visible"
          [columnsConfigurations]="columnsConfiguration"
          [tableSourceData]="clientDataSource"
          (onFilter)="searchClientControl.patchValue($event)"
          [showAddButton]="false"
          [disablePagination]="true"
          [onlySearchComponent]="true"
        >
        </app-search-table>
        <div class="container-fluid d-sm-none">
          <div style="margin: 0px 10px 10px 10px">
            <div class="row">
              <div class="col-12">
                <ng-container>
                  <div
                    class="mobile-session"
                    *ngFor="let client of clientDataSource.data; let i = index"
                  >
                    <div
                      class="card-mobile"
                      [ngClass]="client.checked ? 'card-selected' : ''"
                      (click)="client.checked = !client.checked"
                    >
                      <div class="margin-btm">
                        <label
                          class="paragraph paragraph-1 semibold truncate-line"
                          >{{ client.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="row" *ngIf="globalFunctions.isMobile()">
      <div class="col-12 mb-3">
        <button
          mat-button
          class="btn btn-primary subtitle subtitle-1 semibold white w-100"
          (click)="accept()"
        >
          Confirmar
        </button>
      </div>
      <div class="col-12">
        <button
          mat-button
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color w-100"
          (click)="reject()"
        >
          Cancelar
        </button>
      </div>
    </div>
    <button
      mat-button
      class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
      *ngIf="!globalFunctions.isMobile()"
      (click)="reject()"
    >
      {{ isEdit && isEdit == true ? "Não" : "Cancelar" }}</button
    >&nbsp;
    <button
      mat-button
      class="btn btn-primary subtitle subtitle-1 semibold white"
      *ngIf="!globalFunctions.isMobile()"
      (click)="accept()"
    >
      {{ isEdit && isEdit == true ? "Sim" : "Confirmar" }}</button
    >&nbsp;
  </ng-template>
</p-dialog>
