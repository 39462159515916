<p-dialog [(visible)]="visible" [style]="{width: '1000px', height: '500px'}" class="medium-l" [modal]="true"
  [draggable]="false" [resizable]="false" [ngClass]="{'medium-mobile': globalFunctions.isMobile()}"
  (onHide)="close()">
  <ng-template pTemplate="header" class="mt-3 py-0">
    <span class="subtitle subtitle-2 semibold mt-2 pl-2">
      Usuários associados
    </span>
  </ng-template>
  <form class="mt-4">
    <app-search-table *ngIf="visible"
    [columnsConfigurations]="columnsConfiguration"
    [tableSourceData]="dataSource"
    (onFilter)="searchUserControl.patchValue($event)"
    [showAddButton]="false"
    [showMobileVision]="true"
    (onPageCount)="onPageChange($event)"
    [pageLength]="pageLength"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize">
    </app-search-table>
  </form>
</p-dialog>