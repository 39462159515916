import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable, catchError, lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../components/toaster/toast/toast.component';
import { Partner } from '../../domains/partner/partner';
import { PcvSearchResponsePage } from '../../domains/partner/pcv-search-response-dto';
import { PartnerServiceInterface } from '../../interfaces/partner.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PartnerServiceMock implements PartnerServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }
  getActivePartnersByClientId(id: number): Promise<Partner[]> {
    throw new Error('Method not implemented.');
  }

  async getById(id: number): Promise<Partner> {
    try {
      const network = await lastValueFrom(
        this.http.get<Partner>(this.urlBase + `/networks'/${id}`)
      );
      return network;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getAll(filter?: Optional): Promise<Partner[]> {
    try {
      let partners: Array<Partner> = [];

      if (filter) {
        partners.push(
          await lastValueFrom(
            this.http.get<Partner>(
              this.urlBase + `/partners/` + Object.values(filter)[0]
            )
          )
        );
      } else {
        partners = await lastValueFrom(
          this.http.get<Array<Partner>>(this.urlBase + `/partners`)
        );
      }

      if (partners) {
        return partners;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  findClientPcvPage(
    clientId: number,
    page: number,
    pageSize: number,
    filter: string
  ): Observable<PcvSearchResponsePage | null> {
    return this.http
      .get<PcvSearchResponsePage>(`${this.urlBase}/partners/list/${clientId}`, {
        params: {
          filter: filter,
          page: page,
          pageSize: pageSize,
        },
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return of(null);
        })
      );
  }

  findAdminPcvPage(
    page: number,
    pageSize: number,
    filter: string
  ): Observable<PcvSearchResponsePage | null> {
    return this.http
      .get<PcvSearchResponsePage>(`${this.urlBase}/partners/list-admin`, {
        params: {
          filter: filter,
          page: page,
          pageSize: pageSize,
        },
      })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return of(null);
        })
      );
  }

  async save(
    partner: Partner,
    image?: File,
    attachments?: File[]
  ): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.post(this.urlBase + `/partners`, partner, {
          headers: { 'Content-Type': 'application/json' },
        })
      );

      if (response) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    return false;
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/partners/${id}`, {
          observe: 'response',
        })
      );
      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async edit(
    partner: Partner,
    image?: File,
    attachments?: File[]
  ): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.put(this.urlBase + `/partners/${partner.id}`, partner, {
          headers: { 'Content-Type': 'application/json' },
        })
      );

      if (response) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    return false;
  }
}
