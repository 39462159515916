import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { forkJoin, map, of, startWith, switchMap } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { Group } from 'src/app/shared/domains/group';
import { Authority } from 'src/app/shared/domains/profile/authority';
import { Profile } from 'src/app/shared/domains/profile/profile';
import { ProfileGeneralData } from 'src/app/shared/domains/profile/profile-general-data';
import { Role } from 'src/app/shared/domains/profile/role';
import { User } from 'src/app/shared/domains/user';
import { ClientService } from 'src/app/shared/services/client.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

@Component({
  selector: 'app-user-profiles',
  templateUrl: './user-profiles.component.html',
  styleUrls: ['./user-profiles.component.scss'],
})
export class UserProfilesComponent implements OnInit {
  isEditionMode: boolean = false;
  generalData: ProfileGeneralData = new ProfileGeneralData();
  clientId: number = 0;

  searchControl = new FormControl();
  searchControlUser = new FormControl();

  filteredRoles: Role[] = [];
  allRoles: Role[] = [];
  filteredUsers: User[] = [];

  form: FormGroup = this.formBuilder.group({
    id: new FormControl(undefined),
    isActive: new FormControl(false),
    clientName: new FormControl({ value: '', disabled: true }),
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    authorities: new FormControl([]),
    users: new FormControl([]),
    isEditable: new FormControl(false),
  });

  canCreate: boolean = true;
  canEdit: boolean = true;
  canDelete: boolean = true;
  canChangeSituation: boolean = true;

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  changeCheckbox: boolean = false;
  canAssociateProfiles: boolean = false;
  isFormDirty() {
    this.isDirty = this.form.dirty;
  }

  isDirty: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private toastComponent: ToastComponent,
    private permissionsService: NgxPermissionsService,
    public globalFunctions: GlobalFunctions,
    private clientService: ClientService
  ) {
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          const clientId = params['clientId'];
          const groupId = Number(params['groupId']);

          return forkJoin({
            generalData: clientId
              ? this.profileService.getGeneralData(clientId)
              : of(null),
            profile: groupId
              ? this.profileService.getByGroup(groupId)
              : of(null),
          });
        })
      )
      .subscribe(({ generalData, profile }) => {
        if (generalData) {
          this.setGeneral(generalData);
        }

        if (profile) {
          this.setEditMode(profile as Profile);
        }
      });

    this.route.queryParams.subscribe((params) => {
      this.clientId = params['clientId'];
      this.form.get('clientName')?.setValue(params['clientName']);
    });

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAuth(value))
      )
      .subscribe((response) => (this.filteredRoles = response));

    this.searchControlUser.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterUser(value))
      )
      .subscribe((response) => (this.filteredUsers = response));

    this.form.valueChanges.subscribe(() => {
      this.isDirty = this.form.dirty;
    });
  }

  limitInputLength(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    const maxLength = 200;

    if (currentValue.length > maxLength) {
      this.form.get('name')?.setValue(currentValue.slice(0, maxLength));
    }
  }

  onCheckboxChange(user: any) {
    user.selected = !user.selected;
    this.isDirty = true;
    const index = this.filteredUsers.findIndex((u) => u.id === user.id);
    if (index !== -1) {
      this.filteredUsers[index].selected = user.selected;
    }
  }

  setGeneral(data: ProfileGeneralData) {
    this.generalData = data;
    this.allRoles = [...data.roles];
    this.filteredRoles = [...this.allRoles]
    this.generalData.users = this.generalData.users.sort((a, b) => {
      return this.globalFunctions.compareString(a.name, b.name);
    });
    this.filteredUsers = this.generalData.users;
  }

  async ngOnInit() {
    this.canCreate = await this.permissionsService.hasPermission(
      ProfilePermissions.CREATE_CLIENT_USER_PROFILE
    );
    this.canEdit = await this.permissionsService.hasPermission(
      ProfilePermissions.UPDATE_CLIENT_USER_PROFILE
    );
    this.canChangeSituation = await this.permissionsService.hasPermission(
      ProfilePermissions.CHANGE_SITUATION_CLIENT_USER_PROFILE
    );
    this.canDelete = await this.permissionsService.hasPermission(
      ProfilePermissions.DELETE_CLIENT_USER_PROFILE
    );
    this.canAssociateProfiles = await this.permissionsService.hasPermission(
      ProfilePermissions.LINKED_USERS_CLIENT_USER_PROFILE
    );

    if (!this.canChangeSituation && !this.isEditionMode)
      this.form.get('isActive')?.disable();
  }

  setEditMode(profile: Profile) {
    this.isEditionMode = true;
    const situationFormControl = this.form.get('isActive');

    this.form.get('id')?.setValue(profile.group.id);
    this.form.get('name')?.setValue(profile.group.name);
    this.form.get('description')?.setValue(profile.group.description);
    situationFormControl?.setValue(profile.group.isActive);

    const userIdsSet = new Set(profile.usersIds);

    this.generalData.users.forEach((user) => {
      if (userIdsSet.has(user.userId || -1)) {
        user.selected = true;
      }
    });

    const authoritiesIdsSet = new Set(profile.authoritiesIds);

    this.allRoles.forEach((role) => {
      role.authorities.forEach((authority) => {
        if (authoritiesIdsSet.has(authority.id)) {
          role.selected = true;
          authority.selected = true;
        }
      });
    });

    this.allRoles.forEach((x) => {
      var exists = !x.description.toLocaleLowerCase().startsWith('gamific') ?
        x.authorities.some(
          (c) => c.selected && c.description.toLocaleLowerCase() != 'visualizar'
        ) : this._gamificationViewAuthoritiesRequired(x);
      if (exists) {
        x.authorities
          .filter((a) => a.description.toLocaleLowerCase() == 'visualizar')
          .map((t) => (t.disabled = true));
      }
    });

    if (!this.canEdit) this.form.disable();
    if (this.canChangeSituation) this.form.get('isActive')?.enable();
  }

  private _gamificationViewAuthoritiesRequired(role: Role): boolean {
    return role.authorities.some(
      (c) => (c.selected && c.description.toLocaleLowerCase() == 'incluir') ||
        (c.selected && c.description.toLocaleLowerCase() == 'editar') ||
        (c.selected && c.description.toLocaleLowerCase() == 'excluir')
    );
  }

  toggle(role: Role) {
    this.isDirty = true;
    this.changeCheckbox = true;
    role.selected = !role.selected;

    const roleSelect = this.allRoles.find(r => r.id === role.id);

    roleSelect!.selected = role.selected
    if (role.authorities.length != roleSelect?.authorities.length) {
      [role, roleSelect].forEach(r => r && this.proccesRole(r));
    } else {
      this.proccesRole(role)
    }
  }

  proccesRole(role: Role) {
    if (role.authorities) {
      role.authorities.forEach((auth) => {
        auth.selected = role.selected;
        auth.disabled = false;

        if (
          auth.description.toLocaleLowerCase() == 'visualizar' &&
          role.selected &&
          role.authorities.length > 1
        ) {
          auth.disabled = true;
        }
      });
    }
  }

  toggleAuth(role: Role, auth: Authority) {
    this.isDirty = true;
    this.changeCheckbox = true;
    auth.selected = !auth.selected;

    const roleSelect = this.allRoles.find(r => r.id === role.id);

    if (role.authorities.length != roleSelect?.authorities.length) {
      [role, roleSelect].forEach(r => r && this.proccesAuthority(r, auth));
    } else {
      this.proccesAuthority(role, auth)
    }
  }

  proccesAuthority(role: Role, auth: Authority) {
    if (auth.description.toLocaleLowerCase() != 'visualizar') {
      role.authorities.forEach((r) => {
        if (role.description.toLocaleLowerCase().startsWith('gamific')) {
          if (r.description.toLocaleLowerCase() == 'visualizar' &&
            (
              auth.description.toLocaleLowerCase() == 'incluir' ||
              auth.description.toLocaleLowerCase() == 'editar' ||
              auth.description.toLocaleLowerCase() == 'excluir'
            )) {
            r.disabled = true;
            r.selected = true;
          }
          return;
        }

        if (r.description.toLocaleLowerCase() == 'visualizar') {
          r.disabled = true;
          r.selected = true;
        }
      });
      if (!auth.selected) {
        let isSelected = false;

        role.authorities.forEach((r) => {
          if (role.description.toLocaleLowerCase().startsWith('gamific')) {
            if (r.description.toLocaleLowerCase() != 'visualizar' &&
              (
                (r.description.toLocaleLowerCase() == 'incluir' && r.selected) ||
                (r.description.toLocaleLowerCase() == 'editar' && r.selected) ||
                (r.description.toLocaleLowerCase() == 'excluir' && r.selected)
              )) {
              isSelected = true;
            }
            return;
          }

          if (r.description.toLocaleLowerCase() != 'visualizar' && r.selected) {
            isSelected = true;
          }
        });

        if (!isSelected) {
          role.authorities.forEach((r) => {
            if (r.description.toLocaleLowerCase() == 'visualizar') {
              r.disabled = false;
            }
          });
        }
      }
    }

    if (auth.selected) {
      role.selected = true;
    } else {
      let isSelectedAuth = false;
      role.authorities.forEach((r) => {
        if (r.selected) isSelectedAuth = true;
      });

      if (!isSelectedAuth) {
        role.selected = false;
      }
    }
  }

  filterAuth(value: string): Role[] {
    const filterValue = value.toLowerCase();

    return this.allRoles
      .map((role) => ({
        ...role,
        authorities: role.authorities.filter((auth) =>
          auth.description.toLowerCase().includes(filterValue)
        ),
      }))
      .filter(
        (role) =>
          role.description.toLowerCase().includes(filterValue) ||
          role.authorities.length > 0
      );
  }

  filterUser(value: string): User[] {
    const filterValue = value.toLowerCase();

    return this.generalData.users.filter((user) =>
      user.name.toLocaleLowerCase().includes(filterValue)
    );
  }

  mapperProfile(): Profile {
    const group: Group = {
      id: this.form.get('id')?.value,
      clientId: this.clientId,
      description: this.form.get('description')?.value,
      isActive: this.form.get('isActive')?.value,
      name: this.form.get('name')?.value,
      isEditable: true,
    };

    const usersIds: number[] = this.generalData.users
      .filter((user) => user.selected)
      .map((u) => u.userId as number);
    const authoritiesIds: number[] = [];

    this.allRoles.forEach((role) => {
      role.authorities.forEach((auth) => {
        if (auth.selected) {
          authoritiesIds.push(auth.id);
        }
      });
    });

    return {
      authoritiesIds: authoritiesIds,
      usersIds: usersIds,
      group: group,
    };
  }

  markFormFieldsAsTouched() {
    Object.values(this.form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  async saveOrEdit() {
    this.markFormFieldsAsTouched();
    if (this.form.valid) {
      const profileDto = this.mapperProfile();

      if (profileDto.group.name == "BENEFICIARY" ||
        profileDto.group.name == "PCV" ||
        profileDto.group.name == "CLIENT" ||
        profileDto.group.name == "DEPENDENT"
      ) {
        this.toastComponent.showWarningCustomMessage(
          'Nome de perfil indisponível.',
          'Por favor, escolha outro!'
        );
      } else {
        if (this.isEditionMode) {
          await this.profileService.edit(profileDto);
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Perfil editado com sucesso!',
            3000
          );
        } else {
          await this.profileService.save(profileDto);
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Perfil salvo com sucesso!',
            3000
          );
        }
        this.router.navigate(['/user-profiles']);
      }
    } else {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );
    }
  }

  confirmCancel() {
    if (this.form.pristine && !this.changeCheckbox && !this.isDirty) {
      this.router.navigate(['/user-profiles']);
    } else {
      this.confirmModal
        ?.showCancelModal('Deseja mesmo cancelar?', '', true)
        .subscribe((isAccepted) => {
          if (isAccepted) {
            this.router.navigate(['/user-profiles']);
          }
        });
    }
  }

  confirmDelete() {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este perfil?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          await this.profileService.deleteByGroup(this.form.get('id')?.value);
          this.toastComponent.showSuccessCustomMessage(
            'Perfil deletado com sucesso!',
            '',
            3000
          );
          this.router.navigate(['/user-profiles']);
        }
      });
  }

  get hasPermissions() {
    if (!this.isEditionMode && this.canCreate) return true;
    if (this.isEditionMode && this.canEdit) return true;
    if (this.canChangeSituation) return true;
    if (this.canAssociateProfiles) return true;

    return false;
  }

  get cannotEditOrCreate() {
    return (
      (!this.isEditionMode && !this.canCreate) ||
      (this.isEditionMode && !this.canEdit)
    );
  }

  private disableSituation() {
    this.form.get('isActive')?.disable();
  }

  showRole(role: Role): boolean {
    return role.authorities.some((auth) => auth.visible);
  }
}
