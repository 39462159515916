import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { debounceTime, distinctUntilChanged, from } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import {
  SearchTableDeleteDialogConfiguration,
  SearchTableDeleteDialogConfigurationConditional,
  SituationDialogConfiguration,
} from 'src/app/shared/components/search/interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from 'src/app/shared/components/search/interfaces/search-table-select-configuration.interface';
import { SelectClientModalComponent } from 'src/app/shared/components/select-client-modal/select-client-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { ClientComboboxResponseDTO } from 'src/app/shared/domains/clients/client-combobox-response-dto';
import {
  PcvSearchResponseDTO,
  PcvSearchResponsePage,
} from 'src/app/shared/domains/partner/pcv-search-response-dto';
import { LocalStorageKeys } from 'src/app/shared/enums/storage-keys';
import { PartnerService } from 'src/app/shared/services/partner.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { masks } from 'src/app/shared/utils/global-variables';

@Component({
  selector: 'app-partners',
  templateUrl: './partners-search.component.html',
  styleUrls: ['./partners-search.component.scss'],
})
export class PartnersSearchComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  @ViewChild('selectClientModal') selectClientModal:
    | SelectClientModalComponent
    | undefined;

  dataSource = new MatTableDataSource<PcvSearchResponseDTO>();
  searchPartnerFormControl = new FormControl();
  clientSelectedFormControl = new FormControl();
  allPartners: Array<PcvSearchResponseDTO> = [];
  masks = masks;
  paginationCard: number = 10;
  pageLength!: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  clientId!: number;
  isClientView = true;

  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  columns: SearchColumnConfiguration[] = [];

  openRegisterModal: boolean = false;
  registerMode: 'create' | 'view' | 'edit' = 'create';
  partnerId: number | null = null;

  selectClient: SearchTableSelectConfiguration = {
    labelValueName: 'tradingName',
    data: [],
    placeholder: 'Cliente',
    disableAddButtonWhileNotSelect: false,
  };

  dialogInfo: SearchTableDeleteDialogConfiguration = {
    onConfirmFn: this.confirmDelete.bind(this),
    title: 'Deseja mesmo excluir este parceiro?',
    description: 'Caso confirme, essa ação não poderá ser desfeita.',
  };

  dialogConditionalInfo: SearchTableDeleteDialogConfigurationConditional = {
    onConfirmFn: this.confirmDelete.bind(this),
    title: 'Esse Parceiro possui benefícios ativos associados.',
    description: 'Tem certeza que deseja excluir mesmo assim?',
    field: 'hasActiveBenefit',
  };

  changeSituationDialogInfo: SituationDialogConfiguration = {
    description: '',
    onConfirmFn: this.changeActivePartner.bind(this),
    title: 'Deseja mesmo ',
    title2: ' este parceiro?',
    situationFieldName: 'isActive',
  };

  constructor(
    private partnerService: PartnerService,
    private router: Router,
    public globalFunctions: GlobalFunctions,
    private toastComponent: ToastComponent,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) {
    from(this.rolesService.hasOnlyRoles('ADMIN')).subscribe((isAdmin) => {
      this.isClientView = !isAdmin;
      if (isAdmin) {
        const params: ParamsProps = {
          filter: '',
          page: 0,
          pageSize: this.pageSize,
        };

        this.getAdminPartners(params);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.verifyPermissions();
    this.searchPartnertControlValueChanges();
    this.clientSelectedFormControlValueChanges();
  }

  async verifyPermissions(): Promise<void> {
    this.canCreate = await this.permissionsService.hasPermission(
      ProfilePermissions.CREATE_PARTNER
    );
    this.canEdit = await this.permissionsService.hasPermission(
      ProfilePermissions.UPDATE_PARTNER
    );
    this.canDelete = await this.permissionsService.hasPermission(
      ProfilePermissions.DELETE_PARTNER
    );

    this.initColumns();
  }

  initColumns() {
    this.columns = [
      {
        columnName: 'Nome',
        valueName: 'name',
        columnValueCssClass: 'column-20',
      },
      {
        columnName: 'CPF/CNPJ',
        valueName: 'identification',
        columnValueCssClass: 'column-15',
      },
      {
        columnName: 'E-mail',
        valueName: 'email',
        columnValueCssClass: 'column-20',
      },
      {
        columnName: 'Telefone',
        valueName: 'phone',
        columnValueCssClass: 'column-20',
        mask: masks.phoneMask,
      },
      {
        columnName: 'Situação',
        valueName: 'isActive',
        toggleConfiguration: {
          valueName: 'isActive',
        },
        columnValueCssClass: 'column-10',
      },
      {
        actionsConfiguration: {
          useEditButton: this.canEdit,
          useViewButton: !this.canEdit,
          useDeleteFeature: true,
          disableEditIcon: 'canEdit',
          disableDeleteIcon: 'canEdit',
        },
        columnName: '',
        valueName: 'actions',
        columnValueCssClass: 'p-0 d-flex justify-content-end',
      },
    ];
  }

  searchPartnertControlValueChanges(): void {
    this.searchPartnerFormControl.valueChanges
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchedValue: string) => {
        this.pageIndex = 0;
        const params: ParamsProps = {
          filter: !!searchedValue ? searchedValue.replace(/\//g, '') : '',
          page: 0,
          pageSize: 10,
        };

        if (this.isClientView) {
          params.clientId = this.clientId;
        }

        this.getPartners(params);
      });
  }

  clientSelectedFormControlValueChanges(): void {
    this.clientSelectedFormControl.valueChanges.subscribe(
      (client: ClientComboboxResponseDTO) => {
        this.clientId = client.id;
        const params: ParamsProps = {
          filter: '',
          page: 0,
          pageSize: 10,
          clientId: client.id,
        };
        this.getPartners(params);
      }
    );
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToNewPartner() {
    this.partnerId = null;
    this.registerMode = 'create';
    this.openRegisterModal = true;
  }

  redirectToEditPartner(pcvSearchResponseDTO: any) {
    if (!pcvSearchResponseDTO.canEdit) return;
    this.partnerId = pcvSearchResponseDTO.id;
    this.registerMode = 'edit';
    this.openRegisterModal = true;
  }

  redirectToViewPartner(pcvSearchResponseDTO: PcvSearchResponseDTO) {
    this.partnerId = pcvSearchResponseDTO.id;
    this.registerMode = 'view';
    this.openRegisterModal = true;
  }

  applyFilter(filter: string) {
    this.searchPartnerFormControl.setValue(filter);
  }

  getPartners(params: ParamsProps) {
    this.isClientView
      ? this.getClientPartners(params)
      : this.getAdminPartners(params);
  }

  getClientPartners(params: ParamsProps) {
    if (params.clientId) {
      this.partnerService
        .findClientPcvPage(
          params.clientId,
          params.page,
          params.pageSize,
          params.filter
        )
        .subscribe((data) => {
          if (!!data) {
            this.configTable(data);
          }
        });
    }
  }

  getAdminPartners(params: ParamsProps) {
    this.partnerService
      .findAdminPcvPage(params.page, params.pageSize, params.filter)
      .subscribe((data) => {
        if (!!data) {
          this.configTable(data);
        }
      });
  }

  configTable(pcvSearchResponsePage: PcvSearchResponsePage) {
    const content = pcvSearchResponsePage.content;
    this.allPartners = content;
    this.pageLength = pcvSearchResponsePage.totalElements;
    this.pageIndex = pcvSearchResponsePage.number;
    this.dataSource = new MatTableDataSource(this.mapperShowTable(content));
  }

  mapperShowTable(data: PcvSearchResponseDTO[]): PcvSearchResponseDTO[] {
    return data.map((partner) => {
      const partnerTable: any = {} as PcvSearchResponseDTO;
      partnerTable.name = partner.name;
      partnerTable.identification = partner.identification;
      partnerTable.email = partner.email;
      partnerTable.phone = partner.phone;
      partnerTable.id = partner.id;
      partnerTable.isActive = partner.isActive;
      partnerTable.hasActiveBenefit = partner.hasActiveBenefit;
      partnerTable.canEdit =
        !this.isClientView ||
        partner.clientId ===
          parseInt(localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID)!);

      return partnerTable;
    });
  }

  sortingDataAccessorMethod = (item: any, property: any) => {
    this.dataSource = new MatTableDataSource(this.allPartners);
    this.dataSource.sortingDataAccessor = (item, property) => {
      const value = item[property];
      if (!isNaN(value) && typeof value !== 'boolean') {
        return value;
      }
      return String(value).toLowerCase();
    };
  };

  removeSorting = (data: Sort) => {
    if (data.direction === '') {
      this.dataSource = new MatTableDataSource(this.allPartners);
    }
  };

  async changeActivePartner(partner: PcvSearchResponseDTO) {
    const success = await this.partnerService.toggle(
      partner.id,
      partner.isActive
    );

    if (success) {
      this.toastComponent.showSuccessCustomMessage(
        `Parceiro ${partner.isActive ? 'ativado' : 'inativado'} com sucesso`,
        '',
        3000
      );
    }

    return success;
  }

  async confirmDelete(partnerTable: any) {
    if (!partnerTable.canEdit) return;
    const response = await this.partnerService.delete(partnerTable.id);

    if (response) {
      const params: ParamsProps = {
        filter: '',
        page: 0,
        pageSize: this.pageSize,
      };

      if (this.isClientView) {
        params.clientId = this.clientSelectedFormControl.value?.id;
      }

      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator?.firstPage();
      this.pageLength = 10;
      this.pageIndex = 0;
      this.toastComponent.showSuccessCustomMessage(
        'Exclusão realizada com sucesso!',
        '',
        3000
      );
      this.getPartners(params);
    }
  }

  onSelectClientEvent(event: ClientComboboxResponseDTO) {
    this.clientSelectedFormControl.setValue(event);
  }

  async onPageChange(event: PageEvent) {
    const page = event.pageIndex;
    const pageSize = event.pageSize;
    this.pageSize = event.pageSize;

    if (page == 0) {
      this.pageIndex = 0;
    }

    const params: ParamsProps = {
      filter: !!this.searchPartnerFormControl?.value
        ? this.searchPartnerFormControl?.value
        : '',
      page: page,
      pageSize: pageSize,
    };

    if (this.isClientView) {
      params.clientId = this.clientSelectedFormControl.value?.id;
    }

    this.getPartners(params);
  }

  getPartnerByMobile(): void {
    const params: ParamsProps = {
      filter: !!this.searchPartnerFormControl?.value
        ? this.searchPartnerFormControl?.value
        : '',
      page: 0,
      pageSize: (this.paginationCard += 10),
    };

    if (this.isClientView) {
      params.clientId = this.clientId;
    }

    this.getPartners(params);
  }

  close() {
    this.openRegisterModal = false;
    if (!!this.clientSelectedFormControl.value) {
      const params: ParamsProps = {
        filter: !!this.searchPartnerFormControl?.value
          ? this.searchPartnerFormControl?.value
          : '',
        page: this.pageIndex,
        pageSize: this.pageSize,
      };
      this.getPartners(params);
    }
  }
}

export interface ParamsProps {
  filter: string;
  page: number;
  pageSize: number;
  clientId?: number;
}
