import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ClientWithGroups,
  GroupResumedDTO,
} from '../../domains/GroupCollaborator/group-collaborator-dto';
import { ClientsWithGroup } from '../../domains/post';
import { GroupCollaboratorService } from '../../services/group-collaborator.service';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(400 + 'ms ease-in')),
      transition('true => false', animate(400 + 'ms ease-out')),
    ]),
  ],
})
export class SelectClientComponent implements OnInit, OnChanges {
  @Input()
  disabled: boolean = false;

  @Input()
  selectedGroups: ClientsWithGroup[] = [];

  @Output()
  onChangeGroups = new EventEmitter<ClientsWithGroup[]>();

  clients: ClientWithGroups[] = [];

  data: ClientNode[] = [];

  get selectedClients() {
    return this.data
    .filter((data) => data.groups?.some((v) => v.checked) || data.allGroups)
    .map(
      (data) =>
        ({
          clientId: data.id,
          groups: data.groups?.filter((c) => c.checked).map((c) => c.id),
        } as ClientsWithGroup)
    )
  }

  constructor(private groupCollaboratorService: GroupCollaboratorService) {}


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedGroups'] ) {
      const selectedGroups = JSON.stringify(this.selectedGroups);
      const clients = JSON.stringify(this.selectedClients);
      if(selectedGroups !== clients) {
        this._bindCheckedGroups();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.clients = await this.groupCollaboratorService.findGroupByClient();

    if (this.clients.length > 0) {
      this.initData();
    }

    if (this.selectedGroups.length > 0) this._bindCheckedGroups();
  }

  private _bindCheckedGroups() {
    this.data.forEach((client) => {
      const selectedClient = this.selectedGroups.find(
        (group) => group.clientId === client.id
      );
      if (selectedClient) {
        client.collapse = false;
        client.allGroups = selectedClient.groups.length <= 0;
        client.groups?.forEach((group) => {
          const selectedGroup = selectedClient.groups.find(
            (g) => g === group.id
          );
          if (selectedGroup) {
            group.checked = true;
          }
        });
      }
    });
  }

  initData() {
    this.data = this.clients.map((data) => ({
      id: data.clientId,
      name: data.name,
      allGroups: false,
      groups: this.addGroups(data.group),
      collapse: true,
    }));
  }

  addGroups(groups: GroupResumedDTO[]): GroupNode[] {
    return groups.map((g) => ({ ...g, checked: false }));
  }

  onSelectAllGroups(clientId: number) {
    this.data.forEach((client) => {
      if (client.id === clientId) {
        client.allGroups = !client.allGroups;
        client.groups?.forEach((group) => {
          group.checked = false;
        });
      }
    });
    this.emitEventChangeClient();
  }

  onSelectGroup(group: any, clientId: number) {
    this.data
      .find((client) => client.id == clientId)!
      .groups!.find((value) => value.id === group.id)!.checked = !group.checked;
    this.emitEventChangeClient();
  }

  emitEventChangeClient() {
    this.onChangeGroups.emit(this.selectedClients);
  }
}

interface ClientNode {
  id: number;
  name: string;
  allGroups?: boolean;
  groups?: GroupNode[];
  collapse: boolean;
}

interface GroupNode {
  id: number;
  name: string;
  checked: boolean;
}
