<div class="container-fluid">
  <div class="margin-15">
    <label class="titles title-2 semibold extended-secondary-darker">Configurar Integração</label>
    <form autocomplete="off" [formGroup]="addNewConfigIntegrationFormGroup">
      <div class="mb-4 p-2">
        <div class="row">
          <div class="col-md-3 col-sm-12 pl-1 d-flex flex-column pr-1">
            <mat-form-field appearance="outline" class="col-md-12 col-sm-12 p-0">
              <mat-label class="paragraph paragraph-2 mat-label">CNPJ</mat-label>
              <input matInput [formControl]="cnpj" readonly mask="00.000.000/0000-00" />
            </mat-form-field>
          </div>
          <div class="col-md-6 col-sm-12 pl-1 pr-1 d-flex flex-column">
            <mat-form-field appearance="outline" class="col-md-12 col-sm-12 p-0">
              <mat-label class="paragraph paragraph-2 mat-label">Razão Social</mat-label>
              <input matInput [formControl]="corporateName" readonly />
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-12 pl-1 pr-1 d-flex flex-column">
            <mat-form-field appearance="outline" class="col-md-12 col-sm-12 p-0">
              <mat-label class="paragraph paragraph-2 mat-label">Nome Fantasia</mat-label>
              <input matInput [formControl]="tradingName" readonly />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 col-sm-12 pl-1 pr-1">
            <mat-form-field appearance="outline" class="mat-input">
              <mat-label class="paragraph paragraph-2 mat-label">Token Comunify</mat-label>
              <input matInput [formControl]="clientToken" readonly />
              <mat-icon matSuffix class="mat-icon click ml-2"
                [ngClass]="!clientToken.value ? 'extended-neutral-light-active' : 'secondary-neutral'"
                [ngStyle]="{ cursor: !clientToken.value ? 'not-allowed' : ''}"
                (click)="!clientToken.value ? $event.preventDefault() : copyTpken()">content_copy</mat-icon>
            </mat-form-field>
          </div>

          <div class="d-flex align-items-center col-sm-2" style="margin-top: -1vw;">
            <button mat-button *ngIf="isUserAdmin" class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
              (click)="confirmTokenGeneration()" [disabled]="isloading || !isActive">
              Gerar Token</button>&nbsp;
          </div>
        </div>

        <div class="row p-2 mt-2">
          <mat-checkbox [disabled]="isloading || !canEdit"
            (click)="!(isloading || !canEdit) && oncheckBoxChange($event)"
            [checked]="addNewConfigIntegrationFormGroup.get('allowDataReception')?.value">
            <span class="paragraph paragraph-2 mat-label">Permitir o recebimento de dados por integração</span>
          </mat-checkbox>
        </div>

        <div>
          <div *ngIf="addNewConfigIntegrationFormGroup.get('allowDataReception')?.value" class="mt-3">
            <div class="row p-2">
              <label class="titles title-1 semibold primary-color">Funcionalidades para integrar</label>
            </div>

            <div class="row">
              <div class="col-xl-4" *ngFor="let item of checkboxList; let i = index">
                <mat-checkbox id="checkbox-{{i}}" style="margin-top: -6px"
                  [disabled]="addNewConfigIntegrationFormGroup.disabled" [formControlName]="item.key">
                  <mat-label class="paragraph paragraph-2 mat-label">{{ item.value }}</mat-label>
                </mat-checkbox>
              </div>
            </div>

            <div class="row p-2 mt-2">
              <label class="titles title-1 semibold primary-color">Período Para Informe de Rendimentos</label>
            </div>

            <div class="row">
              <div class="col-2 pl-1 pr-1">
                <mat-form-field appearance="outline" class="mat-input">
                  <mat-label class="paragraph paragraph-2 mat-label">Início</mat-label>
                  <input matInput [matDatepicker]="dataInicio" formControlName="beginDateIncomeReport" [max]="maxDate"
                    (dateChange)="changeBeginDateIncomeReport()" appMaskDate maxLength="10" [disabled]="!canEdit" />
                  <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
                  <mat-datepicker #dataInicio></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-2 pl-1 pr-1 ">
                <mat-form-field appearance="outline" class="mat-input">
                  <mat-label class="paragraph paragraph-2 mat-label">Fim</mat-label>
                  <input matInput [matDatepicker]="dataFinal" formControlName="endDateIncomeReport" [max]="maxDate"
                    [min]="addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.value" appMaskDate
                    maxLength="10"
                    [disabled]="!addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.value || !canEdit" />
                  <mat-datepicker-toggle matSuffix [for]="dataFinal"></mat-datepicker-toggle>
                  <mat-datepicker #dataFinal maxda></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="row p-2 mt-2">
                <mat-checkbox [disabled]="addNewConfigIntegrationFormGroup.disabled" class="senior-integrator" 
                formControlName="allowSeniorIntegrator" style="width: 200px;">
                  <mat-label class="titles title-1 semibold primary-color">Integrador Senior</mat-label>
                </mat-checkbox>
              <mat-icon class="ml-2 click" (click)="showModalInfoIntegration = true;">info_outline</mat-icon>
            </div>

            <ng-container *ngIf="addNewConfigIntegrationFormGroup.get('allowSeniorIntegrator')?.value">
              <div class="row">
                <div class="col-md-3 pl-1 d-flex flex-column pr-1">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">ID do cliente (Comunify)</mat-label>
                    <input matInput [value]="clientId" [disabled]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Nome do cliente (Comunify)</mat-label>
                    <input matInput [formControl]="corporateName" readonly />
                  </mat-form-field>
                </div>
                <div class="col-md-6  pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Token de integração (Comunify)</mat-label>
                    <input matInput formControlName="seniorToken" readonly />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 pl-1 d-flex flex-column pr-1">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Usuário (Senior)</mat-label>
                    <input matInput formControlName="seniorUser" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Senha (Senior)</mat-label>
                    <input matInput autocomplete="new-password" [type]="hidePassword ? 'password' : 'text'" formControlName="seniorPassword" />
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="mat-icon click">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-6  pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Código de integração (Senior)</mat-label>
                    <input type="number" matInput formControlName="seniorIntegrationCode" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 pl-1 d-flex flex-column pr-1">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Endpoint de integração (Senior)</mat-label>
                    <input matInput formControlName="seniorEndpoint" />
                  </mat-form-field>
                </div>
                <div class="col-md-6 pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Endpoint de relatório (Senior)</mat-label>
                    <input matInput formControlName="seniorEndpointReports" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 pl-1 d-flex flex-column pr-1">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Modelo relatório de Férias Programadas
                      (Senior)</mat-label>
                    <input matInput formControlName="seniorVacationProgrammedModelReport" />
                  </mat-form-field>
                </div>
                <div class="col-md-6 pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Modelo relatório de Pagamento de Férias
                      (Senior)</mat-label>
                    <input matInput formControlName="seniorVacationPaidOffModelReport" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 pl-1 d-flex flex-column pr-1">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Modelo relatório de Holerite (Senior)</mat-label>
                    <input matInput formControlName="seniorPayslipModelReport" />
                  </mat-form-field>
                </div>
                <div class="col-md-6 pl-1 pr-1 d-flex flex-column">
                  <mat-form-field appearance="outline">
                    <mat-label class="paragraph paragraph-2 mat-label">Modelo relatório de Informe de Rendimentos
                      (Senior)</mat-label>
                    <input matInput formControlName="seniorIncomeReportModelReport" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="pl-1 pr-1 d-flex flex-column">
                  <mat-checkbox style="margin-top: -6px"
                       formControlName="allowOnlyExistingEmployees">
                      <mat-label class="paragraph paragraph-2 mat-label" 
                      pTooltip="Somente os colaboradores previamente importados terão seus dados atualizados e novos colaboradores não serão criados via integração.">
                        Bloquear Entrada de Colaboradores Não Importados/Integrados
                      </mat-label>
                    </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

    </form>
    <div class="row justify-content-end">
      <div class="col-sm-8"></div>
      <div class="col-6 col-sm-2">
        <button mat-button class="btn btn-secondary semibold subtitle subtitle-1 primary-color" (click)="goBack()">
          Cancelar
        </button>&nbsp;
      </div>
      <div class="col-6 col-sm-2">
        <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="confirmConfigIntegration()"
          [disabled]="!addNewConfigIntegrationFormGroup.valid || isloading || !canEdit">
          Salvar</button>&nbsp;
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="showModalInfoIntegration" class="medium" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="showModalInfoIntegration = false;">
  <ng-template pTemplate="header" class="mt-3">
    <label class="subtitle subtitle-2 semibold extended-secondary-darker p-2 mt-2">
      {{ "Informações dos campos Integrador Senior"}}
    </label>
  </ng-template>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Usuário (Senior):</b>&nbsp;
        Nome do usuário do Senior que será utilizado para realizar integração.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Senha (Senior):</b>&nbsp;
        Senha de acesso Senior cadastrada para o usuário Senior.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Código de integração (Senior):</b>&nbsp;
        Código Integrador de Pendências - Informação a ser verificada no Senior em Recursos > Web Services > Cadastro Integrações > Identificador. Exemplo de preenchimento do campo: 1.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Endpoint de Integração (Senior):</b>&nbsp;
        Verificar documentação da Senior: Link. 
        <a href="https://documentacao.senior.com.br/gestao-de-pessoas-hcm/6.10.3/#webservices/com_senior_g5_rh_fp_integracoes.htm" target="_blank">https://documentacao.senior.com.br/gestao-de-pessoas-hcm/6.10.3/#webservices/com_senior_g5_rh_fp_integracoes.htm</a></label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Endpoint de Relatório (Senior):</b>&nbsp;
        Verificar documentação da Senior: Link.
        <a href="https://documentacao.senior.com.br/gestao-de-pessoas-hcm/6.10.3/#webservices/com_senior_g5_rh_fp_relatorios.htm" target="_blank">https://documentacao.senior.com.br/gestao-de-pessoas-hcm/6.10.3/#webservices/com_senior_g5_rh_fp_relatorios.htm</a></label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Modelo relatório de Férias Programadas (Senior):</b>&nbsp;
        Informação a ser verificada no Senior em Colaboradores > Férias > listar > Doctos Férias. Informar o nome do modelo de relatório de aviso de férias. Exemplo de preenchimento do campo: FPFE002.COL.</label>
    </div>
  </div>


  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Modelo relatório de Pagamento de Férias (Senior):</b>&nbsp;
        Informação a ser verificada no Senior em Colaboradores > Férias > listar > Doctos Férias. Informar o nome do modelo de relatório de pagamento de férias. Exemplo de preenchimento do campo: FPFE003.COL.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Modelo relatório de Holerite (Senior): </b>&nbsp;
        Informação a ser verificada no Senior em Cálculos > Relatórios > Operacionais > Envelope > Gráficos. Informar o nome do modelo de relatório de holerite. Exemplo de preenchimento do campo: FPEN004.ENV.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label class="paragraph paragraph-3"><b>Modelo relatório de Informe de Rendimentos (Senior):</b>&nbsp;
        Informação a ser verificada no Senior em Anuais > Informe/Dirf > Listar > Informe. Informar o nome do modelo de relatório de informe de rendimentos. Exemplo de preenchimento do campo: FPIN001.ANU.</label>
    </div>
  </div>

  <div class="row justify-content-end" style="margin-top: 10px;">
    <div class="col-sm-4">
      <button mat-button class="btn btn-secondary semibold subtitle subtitle-1 primary-color" (click)="showModalInfoIntegration = false;">
        Fechar
      </button>&nbsp;
    </div>
  </div>
    
</p-dialog>



<app-confirm-modal #confirmModal></app-confirm-modal>