import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import {
  EMPTY as GO_TO_ON_COMPLETE,
  Observable,
  catchError,
  lastValueFrom,
  map,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { Client } from '../domains/client';
import { ClientGeneralData } from '../domains/client-general-data';
import { ClientDTO, InfoClientDTO } from '../domains/clientDto';
import { ClientComboboxResponsePage } from '../domains/clients/client-combobox-response-dto';
import { ClientResumedDTO } from '../domains/clients/client-resumed-dto';
import { ResumedClientDTO } from '../domains/clients/resumed-client-dto';
import { NetworkDTO, NetworkPage } from '../domains/network/network';
import { ForbiddenWordDTO } from '../domains/post';
import { ClientAutoCompleteType } from '../enums/client-auto-complete.enum';
import { ClientServiceInterface } from '../interfaces/client-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService implements ClientServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async getResumed(
    momentIds: number[] = [],
    productIds: number[] = []
  ): Promise<ResumedClientDTO[]> {
    let params = new HttpParams();
    if (momentIds.length != 0)
      params = params.set('momentsIds', `${momentIds}`);
    if (productIds.length != 0)
      params = params.set('productsIds', `${productIds}`);

    try {
      const clients: Array<ResumedClientDTO> = await lastValueFrom(
        this.http.get<Array<ResumedClientDTO>>(
          `${this.urlBase}/clients/resumed/to/benefits/by`,
          { params }
        )
      );
      if (clients) {
        return clients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getAllActive(): Promise<ResumedClientDTO[]> {
    try {
      const clients: Array<ResumedClientDTO> = await lastValueFrom(
        this.http.get<Array<ResumedClientDTO>>(`${this.urlBase}/clients/active`)
      );
      if (clients) {
        return clients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getAllNamesActive(): Promise<ClientResumedDTO[]> {
    try {
      const clients: Array<ClientResumedDTO> = await lastValueFrom(
        this.http.get<Array<ClientResumedDTO>>(
          `${this.urlBase}/clients/active-names`
        )
      );
      if (clients) {
        return clients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getAssociatedClients(clientId: number): Promise<ClientDTO[]> {
    let params = new HttpParams();
    params = params.set('id', `${clientId}`);
    try {
      const clients: Array<ClientDTO> = await lastValueFrom(
        this.http.get<Array<ClientDTO>>(`${this.urlBase}/clients/client-link`, {
          params,
        })
      );
      if (clients) {
        return clients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async findByCnpj(number: string): Promise<ClientDTO | null> {
    let params = new HttpParams();
    params = params.set('number', `${number}`);
    try {
      const client: ClientDTO = await lastValueFrom(
        this.http.get<ClientDTO>(`${this.urlBase}/clients/cnpj`, { params })
      );

      if (client) {
        return client;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    return null;
  }

  async findInfoClientByLink(tradingName: string): Promise<InfoClientDTO> {
    const params = new HttpParams().set('path', `${tradingName}`);

    try {
      return await lastValueFrom(
        this.http.get<InfoClientDTO>(`${this.urlBase}/clients/image`, {
          params,
        })
      );
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getGeneralData(): Promise<ClientGeneralData> {
    try {
      const clientGeneralData: ClientGeneralData = await lastValueFrom(
        this.http.get<ClientGeneralData>(this.urlBase + `/general-data`)
      );

      if (clientGeneralData) {
        return clientGeneralData;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async delete(client: Client): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.delete(this.urlBase + `/clients/${client.id}`).pipe(
          catchError((error) => {
            throw error;
          }),
          map((data) => !!data)
        )
      );
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw error;
    }
  }

  async getAll(filter?: Optional): Promise<Array<Client>> {
    try {
      const params = new HttpParams({ fromObject: { ...(filter as any) } });
      const clientArray: Array<Client> = await lastValueFrom(
        this.http.get<Array<Client>>(this.urlBase + `/clients`, { params })
      );

      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getFilteredNetworks({
    filter,
    pageSize,
    page,
    table,
  }: any): Promise<NetworkPage> {
    try {
      const noticeArray: any = await lastValueFrom(
        this.http.get<Array<NetworkDTO>>(this.urlBase + `/networks/list`, {
          params: {
            filter: filter,
            pageSize: pageSize,
            page: page,
            table: table,
          },
        })
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async save(client: FormData): Promise<string> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.post(this.urlBase + `/clients`, client, {
          observe: 'response',
        })
      );

      if (success.status === 200) {
        return '' + success.body.code;
      }
    } catch (error: any) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error(error.error.message);
    }
    return '';
  }

  get(id: number): Observable<ClientDTO> {
    return this.http.get<ClientDTO>(`${this.urlBase}/clients/${id}`).pipe(
      catchError((error) => {
        this.toastComponent.showApiError(error);
        return GO_TO_ON_COMPLETE;
      })
    );
  }

  async beneficiariesIsEditable(id: number): Promise<boolean> {
    try {
      const result: boolean = await lastValueFrom(
        this.http.get<boolean>(
          `${this.urlBase}/clients/beneficiaries-editable/${id}`
        )
      );

      return result;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  update(client: FormData): Observable<boolean> {
    return this.http
      .put(this.urlBase + '/clients', client, { observe: 'response' })
      .pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return GO_TO_ON_COMPLETE;
        }),
        map(() => true)
      );
  }

  async getBeneficiaryClients(): Promise<Array<ClientDTO>> {
    try {
      const clientArray: Array<ClientDTO> = await lastValueFrom(
        this.http.get<Array<ClientDTO>>(this.urlBase + `/beneficiary-clients`)
      );

      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getClientsCombobox(): Promise<Array<ClientDTO>> {
    try {
      const clientArray: Array<ClientDTO> = await lastValueFrom(
        this.http.get<Array<ClientDTO>>(this.urlBase + `/clients/combobox`)
      );
      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }


  async getClientsRelated(id: number): Promise<Array<ClientDTO>> {
    try {
      const clientArray: Array<ClientDTO> = await lastValueFrom(
        this.http.get<Array<ClientDTO>>(this.urlBase + `/clients/clients-related/${id}`)
      );
      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async setActive(id: number, isActive: boolean): Promise<boolean> {
    let params = new HttpParams().set('value', isActive);
    try {
      await lastValueFrom(
        this.http.put(this.urlBase + `/clients/active-client/${id}`,{}, {params})
      );
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getAllWithSubsidiary(
    id: number,
    includeSubsidiary: boolean = true,
    filter?: Optional
  ): Promise<Array<Client>> {
    try {
      const params = new HttpParams({ fromObject: { ...(filter as any) } });
      const clientArray: Array<Client> = await lastValueFrom(
        this.http.get<Array<Client>>(
          this.urlBase +
            `/clients?id=${id}&includeSubsidiary=${includeSubsidiary}`,
          { params }
        )
      );

      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  getClientsInformationsPagination(
    filter: string,
    page: number,
    pageSize: number,
    clientAutoCompleteType: ClientAutoCompleteType
  ): Observable<ClientComboboxResponsePage> {
    return this.http
      .get<ClientComboboxResponsePage>(
        this.urlBase +
          `/clients/fill-combobox?filter=${filter}&page=${page}&pageSize=${pageSize}&typeAutoComplete=${clientAutoCompleteType}`
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  async saveForbiddenWords(words: ForbiddenWordDTO, clientId: number): Promise<string> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.post(this.urlBase + `/clients/${clientId}/forbidden-words`, words, {
          observe: 'response',
        })
      );

      if (success.status === 200) {
        return '' + success.body.code;
      }
    } catch (error: any) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error(error.error.message);
    }
    return '';
  }

  async updateForbiddenWords(words: ForbiddenWordDTO, clientId: number): Promise<string> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.put(this.urlBase + `/clients/${clientId}/forbidden-words`, words, {
          observe: 'response',
        })
      );

      if (success.status === 200) {
        return '' + success.body.code;
      }
    } catch (error: any) {
      this.toastComponent.showApiError(error);
      throw new Error(error.error.message);
    }
    return '';
  }

  async findForbiddenWords(): Promise<ForbiddenWordDTO> {
    try {
      const forbiddenWords = await lastValueFrom(
        this.http.get<ForbiddenWordDTO>(
          this.urlBase + `/clients/forbidden-words`
        )
      );
      return forbiddenWords;
    } catch (error) {
      throw new Error();
    }
  }

  async isClientIntegrationAllowed(id: number): Promise<Boolean> {
    try {
      const integrationAllowed = await lastValueFrom(
        this.http.get<Boolean>(
          this.urlBase + `/clients/integration-allowed?id=${id}`
        )
      );
      return integrationAllowed;
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
  }
}
